import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "material-symbols/outlined.css";
import classNames from "classnames";

const Checklist = ({
  checklistItems,
  setAllChecked,
  className,
  disabled,
  kioskView = false,
}) => {
  const [checklist, setChecklist] = useState(
    checklistItems.reduce((acc, item) => {
      acc[item.name] = false;
      return acc;
    }, {})
  );

  useEffect(() => {
    const allChecked = Object.values(checklist).every(Boolean);
    setAllChecked(allChecked);
  }, [checklist, setAllChecked]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setChecklist((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div className={classNames("flex flex-col gap-x-large", className)}>
      {checklistItems.map((item) => (
        <label
          key={item.name}
          className={classNames("flex items-start gap-6 cursor-pointer", {
            "opacity-50": disabled,
          })}
        >
          <div className="relative flex items-center mt-[2px]">
            <input
              type="checkbox"
              name={item.name}
              checked={checklist[item.name]}
              onChange={handleCheckboxChange}
              className={`peer appearance-none w-5 h-5 ${
                kioskView
                  ? "h-[2vh] w-[2vh]"
                  : "border border-gray-400 checked:bg-blue"
              } rounded-md shrink-0 checked:border-0`}
              disabled={disabled}
            />
            <span
              className={`select-none cursor-pointer material-symbols-outlined absolute shrink-0 ${
                kioskView
                  ? "text-[#e4e4e4] bg-white rounded-[0.5vh] peer-checked:bg-[#006a2a] peer-checked:text-white h-[2.2vh] w-[2.2vh]"
                  : "text-gray-300"
              } scale-75 -left-0.5 peer-checked:text-white`}
              style={kioskView ? { fontSize: "2.2vh" } : null}
            >
              check
            </span>
          </div>
          <span
            className={`text-primary ${
              kioskView ? "text-white font-normal" : ""
            }`}
          >
            {item.label}{" "}
            {item.isLink && (
              <span className="whitespace-nowrap">
                <a
                  href={item.link}
                  className="text-[#016db8]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>
                <span className="material-symbols-outlined text-[#595f77] align-bottom px-1 scale-75">
                  open_in_new
                </span>
              </span>
            )}
          </span>
        </label>
      ))}
    </div>
  );
};

Checklist.propTypes = {
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isLink: PropTypes.bool,
      link: PropTypes.string,
    })
  ).isRequired,
  setAllChecked: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Checklist.defaultProps = {
  checklistItems: [],
  setAllChecked: () => {},
  className: "",
  disabled: false,
};

export default Checklist;
