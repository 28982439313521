import React from "react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import PaymentConfirmation from "components/tango-confirmation";

export default function PayallButton({
  className,
  setModalOpen,
  ibanIsSet,
  issuedAt,
}) {
  const { t } = useTranslation();

  const payallHeading = issuedAt
    ? t("my_trade_ins.payall_issued_heading")
    : t("my_trade_ins.payall_details_accepted_heading");
  const payallMessage = issuedAt
    ? t("my_trade_ins.payall_issued_message")
    : t("my_trade_ins.payall_details_accepted_message");

  return ibanIsSet || issuedAt ? (
    <PaymentConfirmation heading={payallHeading} message={payallMessage} />
  ) : (
    <div className={className}>
      <Button className="w-full" onClick={() => setModalOpen(true)}>
        {t("my_trade_ins.button_payall")}
      </Button>
    </div>
  );
}
