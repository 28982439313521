import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import locationData from "data/locations.json";
import USFlag from "assets/usFlag.svg";
import DEFlag from "assets/deFlag.svg";
import StoreIcon from "assets/icons/storefront.svg";
import MailIcon from "assets/icons/package.svg";
import { Button } from "components/button";
import { useCurrentLocation, getQueryParams } from "lib/utils";

const flagMap = {
  US: USFlag,
  DE: DEFlag,
};

const generateLocationUrl = (location, queryParams) => {
  const newQueryParams = { ...queryParams, state: location.state };

  return `/${location.locale}${window.location.pathname.replace(`/en-US`, ``).replace(`/de-DE`, ``)}?${createSearchParams(newQueryParams).toString()}`;
};

export default function LocaleModal({ setModalOpen = () => {}, title }) {
  const [selectedLocale, setSelectedLocale] = useState(null);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const { location: currentLocation } = useCurrentLocation();

  useEffect(() => {
    if (!selectedLocale && currentLocation.locale) {
      setSelectedLocale(generateLocationUrl(currentLocation, queryParams));
    }
  }, [currentLocation, queryParams, selectedLocale]);

  const { t } = useTranslation();

  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-50 flex items-center">
      <motion.div
        className="bg-[#000000b6] w-full h-full fixed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={() => setModalOpen(false)}
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
        className="bg-white rounded-3xl w-[min(500px,90vw)] mx-auto overflow-scroll px-3 md:px-5 py-6 relative"
      >
        <h1 className="text-4xl font-bold mb-4">
          {title || t("ui.locale_modal.title")}
        </h1>
        <div className="flex flex-col gap-[4px]">
          {locationData.map((location, index) => (
            <div
              key={index}
              className={`flex items-center gap-4 py-4 px-2 md:px-4 ${
                selectedLocale === generateLocationUrl(location, queryParams) &&
                " bg-blue-300"
              } ${
                selectedLocale !== generateLocationUrl(location, queryParams) &&
                "hover:bg-gray-300/25"
              } rounded-2xl cursor-pointer`}
              onClick={() =>
                setSelectedLocale(generateLocationUrl(location, queryParams))
              }
            >
              <img
                src={flagMap[location.country]}
                alt={`${location.country} flag`}
                className="w-10 h-10 rounded-full ml-0 md:ml-2"
              />
              <div className="flex flex-col">
                <div className="font-bold">{location.modalName}</div>
                <div className="text-black-400 whitespace-nowrap">
                  {location.languageName} | {location.currencySymbol}
                </div>
                <div className="flex gap-2 text-black-400 flex-nowrap">
                  {location.takebackMethod.includes("mail") && (
                    <div className="flex gap-1 whitespace-nowrap">
                      <img
                        src={MailIcon}
                        alt="Storefront Icon"
                        className="scale-75"
                      />
                      {t("trade-in.summary.mail_in")}
                    </div>
                  )}
                  {location.takebackMethod.some((method) =>
                    /in_store/.test(method)
                  ) && (
                    <div className="flex gap-1 whitespace-nowrap">
                      <img
                        src={StoreIcon}
                        alt="Package icon"
                        className="scale-75"
                      />
                      {t("trade-in.summary.in_store_drop_in")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={() => window.location.assign(selectedLocale)}>
            Save
          </Button>
        </div>
      </motion.div>
    </div>
  );
}
