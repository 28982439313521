import { useCallback, useEffect, useState } from "react";
import LegoLogo from "assets/LegoLogo.svg";
import { calculateEstimate } from "lib/calculateEstimate";
import CryptoJS from "crypto-js";

import Start from "./screens/start";
import TermsKiosk from "./screens/terms";
import PlaceBricks from "./screens/place-bricks";
import Scale from "./screens/scale";
import ClearTray from "./screens/clear-tray";
import PlaceMoreBricks from "./screens/place-more-bricks";
import Finish from "./screens/finish";
import WeightConfirmation from "./screens/weight-confirm";
import MultitraySummary from "./screens/multitray-summary";
import CancelConfirm from "./screens/cancel-confirm";
import FinishConfirm from "./screens/finish-confirm";
import FinishMultitraySummary from "./screens/finish-multitray-summary";

const { REACT_APP_API } = process.env;
export const SIGNING_KEY = `cdjedlehccbhnbukhtdlrnlifgtdhlnltglifgtdhlctlhuejt`;

export default function Kiosk() {
  //navigation
  const [screenIndex, setScreenIndex] = useState("start");
  const [previousScreen, setPreviousScreen] = useState(null);

  //state to prevent user from cancelling after first lego has been dropped
  const [preventCancel, setPreventCancel] = useState(false);

  //scale input processing
  const [number, setNumber] = useState("");
  const [inputWeight, setInputWeight] = useState(0);
  const [loggedWeights, setLoggedWeights] = useState([]);
  const loggedTotal = loggedWeights.reduce(
    (total, weight) => total + weight,
    0
  );
  const weight = loggedTotal + inputWeight;
  const estimate = calculateEstimate(weight.toFixed(1), "US");

  //prev customer logged weights
  const [prevCustomerLoggedWeights, setprevCustomerLoggedWeights] = useState(
    []
  );

  const convertWeight = (weight) => {
    const weightInlbs = weight * 2.20462;
    return weightInlbs;
  };

  useEffect(() => {
    const checkAndReload = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      if (
        (currentHour === 6 && currentMinute === 0) ||
        (currentHour === 22 && currentMinute === 0)
      ) {
        window.location.reload();
      }
    };

    const intervalId = setInterval(checkAndReload, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      const keyPressed = event.key;
      if (!isNaN(keyPressed) || keyPressed === ".") {
        setNumber((prev) => (prev + keyPressed).slice(0, 6));
      } else if (keyPressed === "Enter") {
        const newWeight = parseFloat(number).toFixed(2);
        setNumber("");

        const weightDifference = Math.abs(newWeight - inputWeight);

        if (!isNaN(newWeight) && weightDifference >= 0.045) {
          const convertedWeight = convertWeight(newWeight);
          setInputWeight(parseFloat(convertedWeight));

          // Send to KV
          const timestamp = new Date().toISOString();
          getLocation().then((location) => {
            sendToKV({ weight: newWeight, location, timestamp });
          });
        }
      }
    },
    [number, inputWeight]
  );

  const getLocation = async () => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          () => {
            resolve();
          }
        );
      }
    });
  };

  const sendToKV = async (data) => {
    try {
      await fetch(`${REACT_APP_API}/audit/scale`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error sending data to KV:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleKeyPress(event);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyPress]);

  const signedWeight = CryptoJS.SHA256(
    `${loggedTotal.toFixed(1)}_${SIGNING_KEY}`
  ).toString(CryptoJS.enc.Hex);

  const logWeight = (weight) => {
    if (weight !== 0) {
      // Convert weight to a number with 1 decimal place and log it
      setLoggedWeights([...loggedWeights, parseFloat(weight.toFixed(1))]);
    }
  };

  const removeLastLoggedWeight = () => {
    const newLoggedWeights = [...loggedWeights];
    newLoggedWeights.pop();
    setLoggedWeights(newLoggedWeights);
  };

  const [bgColorScale, setBgColorScale] = useState(false);

  //screen map as "mini router"
  const navigate = (newScreenIndex) => {
    setPreviousScreen(screenIndex);
    setScreenIndex(newScreenIndex);
  };

  const screenMap = {
    start: {
      element: (
        <Start
          navigate={navigate}
          setLoggedWeights={setLoggedWeights}
          loggedWeights={loggedWeights}
          prevCustomerLoggedWeights={prevCustomerLoggedWeights}
          setprevCustomerLoggedWeights={setprevCustomerLoggedWeights}
        />
      ),
      bgColor: "blue",
    },
    terms: {
      element: (
        <TermsKiosk navigate={navigate} setInputWeight={setInputWeight} />
      ),
      bgColor: "green",
    },
    "place-bricks": {
      element: (
        <PlaceBricks
          navigate={navigate}
          inputWeight={inputWeight}
          previousScreen={previousScreen}
          removeLastLoggedWeight={removeLastLoggedWeight}
        />
      ),
      bgColor: "blue",
    },
    scale: {
      element: (
        <Scale
          navigate={navigate}
          weight={weight}
          estimate={estimate}
          logWeight={logWeight}
          inputWeight={inputWeight}
          loggedWeights={loggedWeights}
          removeLastLoggedWeight={removeLastLoggedWeight}
          setInputWeight={setInputWeight}
          bgColorScale={bgColorScale}
          setBgColorScale={setBgColorScale}
        />
      ),
      bgColor: !bgColorScale ? "blue-200" : "blue",
    },
    "multitray-summary": {
      element: (
        <MultitraySummary
          navigate={navigate}
          weight={weight}
          estimate={estimate}
          loggedWeights={loggedWeights}
          signedWeight={signedWeight}
          previousScreen={previousScreen}
          inputWeight={inputWeight}
        />
      ),
      bgColor: "blue",
    },
    "cancel-confirm": {
      element: (
        <CancelConfirm
          navigate={navigate}
          previousScreen={previousScreen}
          loggedWeights={loggedWeights}
          setInputWeight={setInputWeight}
        />
      ),
      bgColor: "blue",
    },
    "clear-tray": {
      element: (
        <ClearTray
          navigate={navigate}
          inputWeight={inputWeight}
          removeLastLoggedWeight={removeLastLoggedWeight}
          logWeight={logWeight}
          setInputWeight={setInputWeight}
          preventCancel={preventCancel}
          setPreventCancel={setPreventCancel}
        />
      ),
      bgColor: "blue",
    },
    "place-more-bricks": {
      element: (
        <PlaceMoreBricks
          navigate={navigate}
          loggedWeight={loggedTotal}
          removeLastLoggedWeight={removeLastLoggedWeight}
          logWeight={logWeight}
          setInputWeight={setInputWeight}
        />
      ),
      bgColor: "blue",
    },
    "weight-confirm": {
      element: (
        <WeightConfirmation
          navigate={navigate}
          removeLastLoggedWeight={removeLastLoggedWeight}
        />
      ),
      bgColor: "green",
    },
    finish: {
      element: (
        <Finish
          navigate={navigate}
          weight={loggedTotal}
          loggedWeights={loggedWeights}
          signedWeight={signedWeight}
        />
      ),
      bgColor: "blue-200",
    },
    "finish-multitray-summary": {
      element: (
        <FinishMultitraySummary
          navigate={navigate}
          weight={loggedTotal}
          loggedWeights={loggedWeights}
          signedWeight={signedWeight}
          previousScreen={previousScreen}
        />
      ),
      bgColor: "blue",
    },
    "finish-confirm": {
      element: (
        <FinishConfirm
          navigate={navigate}
          previousScreen={previousScreen}
          setprevCustomerLoggedWeights={setprevCustomerLoggedWeights}
          loggedWeights={loggedWeights}
        />
      ),
      bgColor: "blue",
    },
  };

  //vh used as unit for font size and spacing to ensure consistent scaling across portrait and landscape orientations
  return (
    <div
      className={`w-screen h-screen ${
        screenMap[screenIndex].bgColor === "blue-200"
          ? "bg-[#e2f3ff]"
          : screenMap[screenIndex].bgColor === "yellow-transient"
            ? "bg-gradient-to-b from-[#FFF087] via-[#FFE953] to-[#FFE21A]"
            : "bg-" + screenMap[screenIndex].bgColor
      } flex flex-col items-center overflow-hidden font-bold`}
    >
      {/* the below width allows some expansion for screens wider than 9:16 but optimises for portrait ratio */}
      <div className="w-[min(65vh,100vw)] h-full py-[4vh] flex flex-col items-center relative">
        <img
          src={LegoLogo}
          alt="Lego logo"
          className={`${
            screenIndex === "start" ? "pt-[10vh]" : ""
          } w-[8vh]  relative z-10 mt-[2vh]`}
        />
        {screenMap[screenIndex].element}
      </div>
    </div>
  );
}
