import { useUpdateUserPreferences } from "api/use-update-user-preferences";
import { Button } from "components/button";
import SingleCheckbox from "components/checkbox";
import { Header } from "components/header";
import AuthContext from "contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Unsubscribe = () => {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [isSubscribedToMailReminders, setIsSubscribedToMailReminders] =
    useState(user.u_lego_enable_marketing_emails === "true");
  const [isSubscribedToSurvey, setIsSubscribedToSurvey] = useState(
    user.u_lego_enable_survey_emails === "true"
  );
  const [isNotInterested, setIsNotInterseted] = useState(false);

  const [config, updatePreferences] = useUpdateUserPreferences();

  useEffect(() => {
    if (isNotInterested) {
      setIsSubscribedToMailReminders(false);
      setIsSubscribedToSurvey(false);
    }
  }, [isNotInterested]);

  useEffect(() => {
    setIsSubscribedToMailReminders(
      user.u_lego_enable_marketing_emails === "true"
    );
    setIsSubscribedToSurvey(user.u_lego_enable_survey_emails === "true");
  }, [user.u_lego_enable_marketing_emails, user.u_lego_enable_survey_emails]);

  const handleSave = () => {
    const preferences = {
      isSubscribedToSurvey,
      isSubscribedToReminders: isSubscribedToMailReminders,
    };
    updatePreferences(preferences);
  };

  return (
    <div className="min-h-screen w-screen">
      <Header classNames="bg-yellow border-none" />
      <div className="w-full px-8 container mx-auto mt-xxx-large md:mt-xxxx-large max-w-[920px]">
        <h1 className="text-6xl mb-large">{t("email-preferences.title")}</h1>
        <p className="mb-xx-large text-black-300">{user.email}</p>

        {config.success !== undefined && (
          <div className="w-full bg-green-300 border-l-8 border-green text-xl rounded-lg py-4 mt-8 px-4 flex flex-col mb-xx-large">
            <h2 className="text-black text-2xl font-bold">
              {t("email-preferences.updateSuccess")}
            </h2>
            <p className="text-black">
              {t("email-preferences.updateSuccessMessage")}
            </p>
          </div>
        )}

        <div className="max-w-[600px]">
          <h3 className="mb-x-large font-bold text-2xl">
            {t("email-preferences.subtitle")}
          </h3>
          <div className="mb-x-large">{t("email-preferences.selectOne")}</div>
          <p className="text-secondary text-base mb-large">
            <SingleCheckbox
              label={t("email-preferences.marketingOption")}
              checked={isSubscribedToSurvey}
              setChecked={setIsSubscribedToSurvey}
            />
          </p>
          <p className="text-secondary text-base mb-large">
            <SingleCheckbox
              label={t("email-preferences.updatesOption")}
              checked={isSubscribedToMailReminders}
              setChecked={setIsSubscribedToMailReminders}
            />
          </p>
          <p className="text-secondary text-base mb-large">
            <SingleCheckbox
              label={t("email-preferences.selectAll")}
              checked={isNotInterested}
              setChecked={setIsNotInterseted}
            />
          </p>

          <Button
            onClick={handleSave}
            disabled={config.isLoading}
            className="mt-xx-large"
          >
            {t("email-preferences.save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
