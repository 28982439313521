import React, { useState, Fragment } from "react";
import { Input } from "components/input";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";

const PasswordInput = ({ label, value, onChange, passwordAlarm }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      <div className="relative">
        <Input
          label={label}
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          value={value}
          onChange={onChange}
        />
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-6 md:top-1/2 top-11 transform md:translate-y-1 cursor-pointer text-gray-600"
        >
          {showPassword ? (
            <EyeOpenIcon width={24} height={24} />
          ) : (
            <EyeNoneIcon width={24} height={24} />
          )}
        </span>
      </div>
    </Fragment>
  );
};

export default PasswordInput;
