import React, { Fragment, useState } from "react";

// import Bricks from "../assets/bricks.svg";
import KioskStartButton from "../components/start-button";
import BrickWall from "../components/BrickWall";

export default function Scale({
  navigate,
  weight,
  estimate,
  inputWeight,
  loggedWeights,
  removeLastLoggedWeight,
  setBgColorScale,
  logWeight,
  setInputWeight,
}) {
  // get value between 0 and 100 depending on weight to control animation
  const wallAnimationProgress = 50;

  //cancel confirmation UI
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  // const sumLoggedWeights = loggedWeights.reduce(
  //   (total, weight) => total + weight,
  //   0
  // );

  // const lastAddedWeight = weight - sumLoggedWeights;
  // const newWeight = weight - lastAddedWeight;

  const handleCancel = () => {
    if (loggedWeights && loggedWeights.length > 0) {
      setInputWeight(0);
      navigate("clear-tray");
    } else {
      navigate("cancel-confirm");
    }
    // if (loggedWeights.length > 0) {
    //   removeLastLoggedWeight();
    //   // setInputWeight(newWeight);
    // } else {
    // }
  };

  // const handleAddTray = () => {
  //   logWeight();
  //   navigate("clear-tray");
  // };

  // const disabledCancel = loggedWeights.length > 0;

  return (
    <div className="flex flex-col items-center justify-between w-full h-full relative">
      {loggedWeights.length > 0 && (
        <button
          className="fixed top-0 right-0 bg-blue-300 rounded-full m-16 z-10"
          onClick={() => navigate("multitray-summary")}
        >
          <span className="material-symbols-outlined !text-[48px] p-[26px]">
            all_inbox
          </span>
          <div className="absolute top-[.5vh] right-[.8vh] text-[0.8vh] rounded-full bg-red w-[1.1vh] h-[1.1vh] text-white flex justify-center items-center">
            {loggedWeights.length + 1}
          </div>
        </button>
      )}
      <div className="absolute top-0 left-0 w-full h-full z-0">
        {!showCancelConfirmation && (
          <BrickWall
            initialWeight={weight}
            speedFactor={5}
            animationProgress={wallAnimationProgress}
          />
        )}
      </div>
      <div className="flex flex-col px-[4vh] relative z-10">
        <p
          className={`${
            !showCancelConfirmation
              ? "text-[90px] text-black"
              : "text-[120px] text-white"
          } text-center max-w-[45vh] mt-[3vh]`}
        >
          Ka-ching! Your total
          <br />
          LEGO® stash weighs in at:
        </p>
        {!showCancelConfirmation && (
          <div className="flex w-full pl-[110px] justify-center items-baseline">
            <div className="text-[252px]">{weight.toFixed(1)}</div>
            <div className="text-[69px] ml-[1.5vh]">lbs</div>
          </div>
        )}
        {loggedWeights.length > 0 && (
          <div className="flex w-full justify-center text-[50px] mt-[-1vh] mb-[2vh]">
            <span className="font-normal">Current weighing:</span>
            <span className="ml-[1vh]">{inputWeight.toFixed(1)} lbs</span>
          </div>
        )}
        {!showCancelConfirmation && (
          <div className="flex w-full justify-center gap-[2vh]">
            <div className="flex flex-col items-center">
              <div className="flex items-top">
                <div className="text-[40px] mr-[1rem] mt-[1vh]">$</div>
                <div className="text-[90px]">{estimate.gift}</div>
              </div>
              <div
                style={{ fontWeight: 400 }}
                className="text-[40px] w-[16vh] text-center text-black-400"
              >
                As a LEGO e-Gift Card
              </div>
            </div>
            <div className="flex items-center gap-[1vh]">
              <div className="w-[1vh] h-[.1vh] bg-[#7C7C7C]"></div>
              <div className="text-[45px] font-normal">OR</div>
              <div className="w-[1vh] h-[.1vh] bg-[#7C7C7C]"></div>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-top">
                <div className="text-[40px] mr-[1rem] mt-[1vh]">$</div>
                <div className="text-[90px]">{estimate.payment}</div>
              </div>
              <div
                style={{ fontWeight: 400 }}
                className="text-[40px] w-[16vh] text-center text-black-400"
              >
                As Digital Cash{" "}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`grid ${
          showCancelConfirmation ? "grid-cols-2" : "grid-cols-2"
        } w-full gap-[48px] px-[4vh] relative`}
        style={{ zIndex: 1000 }}
      >
        {!showCancelConfirmation ? (
          <Fragment>
            <KioskStartButton
              // className={`${disabledCancel && "text-[#C9C9C9] bg-white"}`}
              onClick={() => handleCancel()}
              icon={loggedWeights.length > 0 ? "arrow_back" : "close"}
            >
              {loggedWeights.length > 0 ? "Back" : "Cancel"}
            </KioskStartButton>
            {/* <KioskStartButton
              className=""
              onClick={() => {
                handleAddTray();
              }}
              icon="add"
            >
              <div className="text-left">Add tray</div>
            </KioskStartButton> */}
            <KioskStartButton
              className="font-semibold"
              onClick={() => {
                logWeight(inputWeight);
                navigate("weight-confirm");
              }}
              disabled={weight === 0}
              icon="arrow_forward"
              iconColor="black"
              color="yellow"
            >
              Continue
            </KioskStartButton>
          </Fragment>
        ) : (
          <Fragment>
            <KioskStartButton
              className=""
              onClick={() => {
                navigate("start");
                setBgColorScale(false);
                localStorage.removeItem("hasAnimated");
              }}
              iconShow={false}
            >
              Start over
            </KioskStartButton>
            <KioskStartButton
              className="font-bold"
              onClick={() => {
                setShowCancelConfirmation(false);
                setBgColorScale(false);
              }}
              color="yellow"
              iconColor="black"
            >
              Cancel
            </KioskStartButton>
          </Fragment>
        )}
      </div>
    </div>
  );
}
