import React, { useCallback, useEffect, useMemo, useState } from "react";
import closeSvg from "./svgs/close.svg";
import barcodeScannerSvg from "./svgs/barcode_scanner.svg";
import { debounce } from "@mui/material";
import { useTakebackByShippingTracking } from "../../../api/use-takebacks";
import { useTrackingNumberValidator } from "./useTrackingNumberValidator";

export const PackageLookup = ({ onClose }) => {
  const [trackingId, setTrackingId] = useState("");
  const [isValidTrackingId, setIsValidTrackingId] = useState(false);

  const { upsTracking, dhlTracking, shippingCode } =
    useTrackingNumberValidator(trackingId);
  const [isValidTrackingNumber, setIsValidTrackingNumber] = useState(true);

  useEffect(() => {
    setIsValidTrackingNumber(
      upsTracking || dhlTracking || shippingCode || !trackingId
    );
  }, [dhlTracking, shippingCode, trackingId, upsTracking]);

  const { isLoading: isLoadingTakeback } = useTakebackByShippingTracking({
    upsTracking,
    dhlTracking,
    shippingCode,
    onSuccess: (data) => {
      setIsValidTrackingId(data?.length === 1 ? true : false);
    },
  });

  const trackingInputCallback = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const memorizedDebouncedSetTrackingId = useMemo(
    () => debounce(setTrackingId, 800),
    []
  );

  return (
    <div
      id="modelConfirm"
      className="fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full"
    >
      <div className="relative bg-white h-full grid grid-rows-[6rem_auto_19rem]">
        <div className="flex p-2 bg-gray">
          <button
            onClick={onClose}
            type="button"
            className="rounded-lg text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 inline-flex items-center"
          >
            <img className="w-12 h-12" src={closeSvg} alt="Close" />
          </button>
        </div>

        <div className="flex items-center justify-center">
          <div className="text-center font-sans p-4 bg-white">
            <div className="flex flex-col items-center justify-center">
              <img
                className="w-[12rem]"
                src={barcodeScannerSvg}
                alt="scan your package"
              />
              <p className="text-tertiary text-4xl">
                Using the handheld scanner, scan the tracking barcode affixed to
                the package or enter the tracking code manually.
              </p>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 bg-gray-600 w-full text-4xl font-normal">
          <div className="flex gap-8 flex-col p-8">
            <div className="w-full flex flex-col gap-2">
              <label htmlFor="trackingNumberInput" className="text-white">
                Scan barcode or enter tracking number manually
              </label>
              <input
                ref={trackingInputCallback}
                className="rounded-lg p-2"
                type="text"
                placeholder="Tracking ID"
                onChange={(e) => {
                  setIsValidTrackingNumber(true);
                  memorizedDebouncedSetTrackingId(e.target.value);
                }}
              />
              <p
                className="text-white"
                style={{
                  color: isValidTrackingId ? "inherit" : "red",
                  visibility:
                    isLoadingTakeback || !isValidTrackingNumber
                      ? "visible"
                      : "hidden",
                }}
              >
                {isLoadingTakeback ? "Finding takeback" : "Invalid Tracking ID"}
              </p>
            </div>
            <div className="flex flex-row w-full gap-8">
              <button
                className="rounded-lg border disabled:cursor-not-allowed disabled:bg-gray border-gray-200 bg-blue hover:bg-blue-700 font-bold py-4 rounded w-full m-0"
                disabled={!isValidTrackingId || isLoadingTakeback}
                onClick={() => {
                  alert("Check in package coming soon...");
                }}
              >
                Check In Package
              </button>
              <button
                className="rounded-lg border disabled:cursor-not-allowed disabled:bg-gray border-gray-200 bg-blue hover:bg-blue-700 font-bold py-4 rounded w-full m-0"
                disabled={!isValidTrackingId || isLoadingTakeback}
                onClick={() => {
                  alert("Process Package coming soon...");
                }}
              >
                Process Package
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
