import React from "react";
import { Header } from "components/header";
import { Outlet } from "react-router-dom";
import ImpressumFooter from "components/footer-impressum";

export default function StoreSignupLayout() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow w-full h-[calc(100vh-81px)] overflow-scroll">
        <Outlet />
      </div>
      <ImpressumFooter className="" />
    </div>
  );
}
