import React, { useEffect, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckInQr = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef();
  const handleExit = () => {
    navigate(t(`urls.store_check_in`));
  };
  const handleScanResult = (result) => {
    if (result) {
      navigate(`${t("urls.store_trade_in_start")}?id=${result?.text}`);
      navigate(0);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        containerRef.current.style.height = `${window.innerHeight}px`;
        containerRef.current.style.width = `${window.innerWidth}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="flex flex-col w-full h-full max-w-screen max-h-screen overflow-hidden relative border-4 border-transparent"
    >
      <button
        onClick={handleExit}
        className="absolute top-4 right-4 bg-black text-white rounded-full w-8 h-8 flex items-center justify-center z-10"
      >
        ✕
      </button>
      <QrReader
        constraints={{
          facingMode: "environment",
        }}
        containerStyle={{
          width: "100%",
          height: "100%",
        }}
        videoContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        videoStyle={{
          width: "100%",
          height: "100%",
        }}
        onResult={handleScanResult}
      />
      <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center py-4">
        {t(`store.check-in.scan-qr`)}
      </div>
    </div>
  );
};

export default CheckInQr;
