import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "components/select";
import { Input } from "components/input";
import { Label } from "components/label";
import usStates from "data/us-states.json";
import availableCountries from "data/countries.json";

export const Address = ({ address, setAddress, currentLocation }) => {
  const { t, i18n } = useTranslation();

  const isValidZip = (value) => {
    const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return zipRegex.test(value);
  };
  const [zipBlur, setZipBlur] = useState(false);
  // Filter available countries based on current location
  let filteredCountries = availableCountries;
  if (currentLocation) {
    filteredCountries = availableCountries.filter(
      (country) => country.shorthand === currentLocation.country
    );
  }

  return (
    <div className="w-full flex flex-col gap-x-large">
      <Input
        label={t("ui.input_address_line1")}
        type="text"
        name="addressLine1"
        onChange={(event) =>
          setAddress((prev) => ({ ...prev, addressLine1: event.target.value }))
        }
        value={address.addressLine1}
        pattern="[A-Za-z0-9\s,.\-]+"
        maxlength="35"
        required
      />
      <Input
        label={t("ui.input_address_line2")}
        type="text"
        name="addressLine2"
        onChange={(event) =>
          setAddress((prev) => ({ ...prev, addressLine2: event.target.value }))
        }
        value={address.addressLine2}
        pattern="[A-Za-z0-9\s,.\-]+"
        maxlength="35"
      />
      <div
        className={`flex ${
          i18n.language === "de-DE"
            ? "flex-col-reverse sm:flex-row-reverse"
            : "flex-col sm:flex-row"
        } gap-y-x-large sm:gap-x-x-large w-full`}
      >
        <Input
          label={t("ui.input_city")}
          type="text"
          name="addressCity"
          onChange={(event) =>
            setAddress((prev) => ({ ...prev, addressCity: event.target.value }))
          }
          value={address.addressCity}
          className="flex-1"
        />
        <div className="flex-1 flex-col gap-2">
          <Input
            label={t("ui.input_zip_code")}
            type="text"
            name="addressZip"
            onChange={(event) =>
              setAddress((prev) => ({
                ...prev,
                addressZip: event.target.value,
              }))
            }
            onBlur={() => setZipBlur(true)}
            value={address.addressZip}
            className="flex-1"
          />
          {!isValidZip(address.addressZip) &&
            zipBlur &&
            currentLocation.locale === "en-US" && (
              <div className="mt-2 text-left">
                <span
                  className={`material-symbols-outlined scale-75 text-red align-bottom block`}
                >
                  error
                </span>
                <span className="text-sm">{t("ui.input_zip_code_error")}</span>
              </div>
            )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-y-x-large sm:gap-x-x-large w-full">
        {address.addressCountry === "US" && (
          <div className="flex-1">
            <Label htmlFor="state">{t("ui.select_state")}</Label>
            <Select
              id="state"
              value={address.addressState || "SELECT"}
              onValueChange={(value) =>
                setAddress((prev) => ({ ...prev, addressState: value }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={t("ui.select_state")} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem key="SELECT" value="SELECT" disabled>
                    {t("ui.please_select")}
                  </SelectItem>
                  {usStates.map((state) => (
                    <SelectItem key={state.shorthand} value={state.shorthand}>
                      {state.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
        <div className="flex-1">
          <Label htmlFor="country">{t("ui.select_country")}</Label>
          <Select
            id="country"
            value={address.addressCountry}
            onValueChange={(value) =>
              setAddress((prev) => ({ ...prev, addressCountry: value }))
            }
          >
            <SelectTrigger>
              <SelectValue placeholder={t("ui.select_country")} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {filteredCountries.map((country) => (
                  <SelectItem key={country.shorthand} value={country.shorthand}>
                    {country.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};
