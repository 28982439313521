import { useContext } from "react";
import { Button } from "components/button";
import { Header } from "components/header";
import AuthContext from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
import ScrollToTop from "components/scroll-to-top";
import { Footer } from "components/footer/footer";

// import ImpressumFooter from "components/footer-impressum";

export default function PreperationChecklist() {
  //auth
  const { user } = useContext(AuthContext);

  //translation
  const { t } = useTranslation();

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={`${t("urls.trade_in_weight")}`}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };
  return (
    <div className="min-h-screen w-screen flex flex-col justify-between">
      <div className="mb-20">
        <Header classNames="bg-yellow border-none">
          <div className="flex gap-large">
            <CtaButton className="hidden md:block" />
            <Button
              href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
              variant="secondary"
            >
              {user
                ? t("landing.button_my_trade_ins")
                : t("landing.button_sign_in")}
            </Button>
          </div>
        </Header>
        <ScrollToTop />
        <div className="w-full px-8 container mx-auto mt-24">
          <h1 className="text-6xl">{t("preparation_checklist.title")}</h1>
          <div className="text-black-300 mt-4">
            {t("preparation_checklist.subtitle")}
          </div>
          <div className="mt-10 font-semibold">
            {t("preparation_checklist.checklist_title")}
          </div>
          <ul className="list-disc flex flex-col gap-4 mt-4 ml-4">
            <li> {t("preparation_checklist.checklist_item1")}</li>
            <li> {t("preparation_checklist.checklist_item2")}</li>
            <li> {t("preparation_checklist.checklist_item3")}</li>
            <li> {t("preparation_checklist.checklist_item4")}</li>
            <li> {t("preparation_checklist.checklist_item5")}</li>
          </ul>
        </div>
      </div>
      <Footer />
      {/* <ImpressumFooter /> */}
    </div>
  );
}
