import { useContext } from "react";
import { Button } from "components/button";
import { Header } from "components/header";
import AuthContext from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";
import { Link } from "react-router-dom";
import { useCurrentLocation } from "lib/utils";
import ScrollToTop from "components/scroll-to-top";
import { Footer } from "components/footer/footer";
// import ImpressumFooter from "components/footer-impressum";

export default function PrivacyPolicyPage() {
  //auth
  const { user } = useContext(AuthContext);

  //translation
  const { t, i18n } = useTranslation();

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={`${t("urls.trade_in_weight")}`}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };

  return (
    <div className="min-h-screen w-screen">
      <Header classNames="bg-yellow border-none">
        <div className="flex gap-large">
          <CtaButton className="hidden md:block" />
          <Button
            href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
            variant="secondary"
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <ScrollToTop />
      <div className="w-full px-8 container mx-auto mt-16 mb-16">
        {i18n.language === "en-US" ? (
          <div className="max-w-prose mx-auto">
            <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>

            <p className="mb-6">Last modified: 15th July 2024</p>

            <p className="mb-6">
              The LEGO® Brick Take Back Program is being operated for and on
              behalf of the LEGO Group by Pentatonic Ltd. See{" "}
              <Link
                className="text-blue cursor-pointer"
                to={t("urls.terms_and_conditions")}
              >
                Terms & Conditions
              </Link>
              .
            </p>

            <p className="mb-6">
              Data Protection & Privacy is a priority issue for Pentatonic Ltd &
              the LEGO Group. Therefore, we want to inform you in accordance
              with the applicable data protections provisions about the personal
              data used when you visit our web application and the rights you
              have as a data protection subject.
            </p>

            <h2 className="text-2xl font-semibold mb-4">1. PERSONAL DATA</h2>
            <p className="mb-6">
              This Privacy Policy is based on applicable US privacy laws such as
              the California Consumer Privacy Act (CCPA) and the California
              Privacy Rights Act (CPRA).
            </p>
            <p className="mb-6">
              Subject to this Privacy Policy are personal data. Personal data
              means any information relating to an identified or identifiable
              natural person ("data subject"). An identifiable natural person is
              one who can be identified, directly or indirectly, in particular
              by reference to an identifier such as a name, an identification
              number, location data, an online identifier, or to one or more
              factors specific to the physical, physiological, genetic, mental,
              economic, cultural, or social identity of that natural person.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              2. NAME AND ADDRESS OF THE CONTROLLER
            </h2>
            <p className="mb-6">
              Controller for the purposes of applicable US privacy laws is:
            </p>
            <p className="mb-6">
              Infinitum Technologies Inc., trading as Pentatonic Ltd
              <br />
              2140 S Dupont Highway
              <br />
              Camden, Kent, DE 19934
              <br />
              USA
              <br />
              Email:{" "}
              <a
                href="mailto:support@legobricktakeback.com"
                className="text-blue"
              >
                support@legobricktakeback.com
              </a>
              <br />
              Data Protection Officer is: Philip Mossop
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              3. DATA SHARING WITH LEGO
            </h2>
            <p className="mb-6">
              To effectively manage and operate the LEGO® Brick Take Back
              Program, certain personal data collected through our web
              application will be shared with the LEGO Group and its affiliates.
              This data sharing is essential for processing your requests,
              managing the program, and providing customer support. The shared
              data may include, but is not limited to, contact information, take
              back program information, details of inquiries, Adobe Analytics
              data collected where cookies are enabled, and any other relevant
              information necessary for the effective operation of the program.
              Personally Identifiable Information will not be shared between
              Pentatonic and affiliates of LEGO.
            </p>
            <p className="mb-6">
              The LEGO Group will handle your personal data in compliance with
              applicable US data protection laws. For more details on how LEGO
              processes personal data, please refer to LEGO's privacy policy
              available at{" "}
              <a
                href="https://lego.com/en-us/legal/notices-and-policies/privacy-policy"
                className="text-blue"
              >
                lego.com/en-us/legal/notices-and-policies/privacy-policy
              </a>
            </p>

            <h2 className="text-2xl font-semibold mb-4">4. COOKIES</h2>
            <p className="mb-6">
              Cookies are small data files that your browser places on your
              computer or device. A cookie itself does not contain or collect
              information. However, when it is read by a server via a web
              browser it can help a website or web application deliver a more
              user-friendly service – for example, remembering account details.
            </p>
            <p className="mb-6">
              For more information about cookies and how to manage them, please
              read our full{" "}
              <Link
                to={t("urls.cookie_policy")}
                className="text-blue cursor-pointer"
              >
                Cookie Policy
              </Link>{" "}
              and Cookie Declaration. or click on your cookie settings on{" "}
              <a href="https://legobricktakeback.com" className="text-blue">
                https://legobricktakeback.com
              </a>
              .
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              5. COLLECTION OF GENERAL DATA AND INFORMATION
            </h2>
            <p className="mb-6">
              The LEGO® Brick Take Back Program web application collects a
              series of general data and information when a data subject or
              automated system calls up the website. This general data and
              information are stored in the server log files. Collected may be
              (1) the browser types and versions used, (2) the operating system
              used by the accessing system, (3) the web application from which
              an accessing system reaches our web application (so-called
              referrers), (4) the sub-websites, (5) the date and time of access
              to the Internet site, (6) an Internet protocol address (IP
              address), and (7) any other similar data and information that may
              be used in the event of attacks on our information technology
              systems.
            </p>
            <p className="mb-6">
              When using these general data and information, Pentatonic Ltd does
              not draw any conclusions in respect of the data subject. Rather,
              this information is needed to (1) deliver the content of our web
              application correctly, (2) optimize the content of our web
              application as well as its advertisement, and (3) provide law
              enforcement authorities with the information necessary for
              criminal prosecution in case of a cyber-attack. Therefore,
              Pentatonic Ltd analyzes anonymously collected data and information
              statistically, with the aim of increasing the data protection and
              data security of our enterprise, and to ensure an optimal level of
              protection for the personal data we process. The anonymous data of
              the server log files are stored separately from all personal data
              provided by a data subject.
            </p>

            <h2 className="text-2xl font-semibold mb-4">6. ADOBE ANALYTICS</h2>
            <p className="mb-6">
              On this web application, we use Adobe Analytics. Adobe Analytics
              is a web analytics service provided by Adobe Systems Software
              Ireland Limited. Web analytics is the collection, gathering, and
              analysis of data about the behavior of visitors to websites. A web
              analysis service collects data about the web application from
              which a person has come (the so-called referrer), which sub-pages
              were visited, or how often and for what duration a sub-page was
              viewed. Web analytics are mainly used for the optimization of a
              web application and to carry out a cost-benefit analysis of
              Internet advertising.
            </p>
            <p className="mb-6">
              For web analytics through Adobe Analytics, the controller uses the
              application "anonymizeIp." The IP address of the Internet
              connection of the data subject is anonymized by Adobe when
              accessing our websites.
            </p>
            <p className="mb-6">
              The purpose of the Adobe Analytics component is to analyze the
              traffic on our website. Adobe uses the collected data and
              information to evaluate the use of our web application and to
              provide online reports that show the activities on our websites,
              and to provide other services concerning the use of our Internet
              site for us.
            </p>
            <p className="mb-6">
              Adobe Analytics places a cookie on the information technology
              system of the data subject. The definition of cookies is explained
              above. With the setting of the cookie, Adobe is enabled to analyze
              the use of our website. With each visit, your Internet browser
              will automatically submit data through the Adobe Analytics
              component for the purpose of online advertising and the settlement
              of commissions to Adobe. Adobe gains knowledge of personal
              information, such as the IP address of the data subject, which
              serves Adobe to understand the origin of visitors and clicks, and
              subsequently create commission settlements.
            </p>
            <p className="mb-6">
              The cookie is used to store personal information, such as the
              access time, the location from which the access was made, and the
              frequency of visits of our web application by the data subject.
              With each visit to our Internet site, such personal data,
              including the IP address of the Internet access used by the data
              subject, will be transmitted to Adobe. These personal data are
              stored by Adobe. Adobe may pass these personal data collected
              through the technical procedure to third parties.
            </p>
            <p className="mb-6">
              The data subject may, at any time, prevent the setting of cookies
              through our web application by setting the browser preferences
              accordingly and thus permanently avoid the setting of cookies.
              Such an adjustment to the Internet browser used would also prevent
              Adobe Analytics from setting a cookie on the information
              technology system of the data subject. In addition, cookies
              already in use by Adobe Analytics may be deleted at any time via a
              web browser or other software programs.
            </p>
            <p className="mb-6">
              Additionally, the data subject may object to the collection of
              data that are generated by Adobe Analytics, which is related to
              the use of this website, as well as the processing of this data by
              Adobe. For this purpose, the data subject must download a browser
              add-on at{" "}
              <a
                href="https://www.adobe.com/privacy/opt-out.html"
                className="text-blue"
              >
                https://www.adobe.com/privacy/opt-out.html
              </a>{" "}
              and install it. This browser add-on instructs Adobe Analytics
              through JavaScript that any data and information about the visits
              of Internet pages may not be transmitted to Adobe Analytics. The
              installation of the browser add-ons is considered an objection by
              Adobe. If the information technology system of the data subject is
              later deleted, formatted, or newly installed, the data subject
              must reinstall the browser add-ons to disable Adobe Analytics. If
              the browser add-on was uninstalled by the data subject or any
              other person who is attributable to their sphere of competence, or
              is disabled, the reinstallation or reactivation of the browser
              add-ons may be initiated.
            </p>
            <p className="mb-6">
              Further information and the applicable data protection provisions
              of Adobe may be retrieved at{" "}
              <a
                href="https://www.adobe.com/privacy.html"
                className="text-blue"
              >
                https://www.adobe.com/privacy.html
              </a>{" "}
              and at{" "}
              <a
                href="https://www.adobe.com/legal/terms.html"
                className="text-blue"
              >
                https://www.adobe.com/legal/terms.html
              </a>
              . Adobe Analytics is further explained at{" "}
              <a
                href="https://www.adobe.com/analytics/adobe-analytics.html"
                className="text-blue"
              >
                https://www.adobe.com/analytics/adobe-analytics.html
              </a>
            </p>

            <h2 className="text-2xl font-semibold mb-4">7. CONTACT</h2>
            <p className="mb-6">
              The data subject can contact the controller by sending an e-mail
              to{" "}
              <a
                href="mailto:support@legobricktakeback.com"
                className="text-blue"
              >
                support@legobricktakeback.com
              </a>
              . If a data subject contacts the controller by e-mail the personal
              data transmitted by the data subject are automatically stored.
              Such personal data transmitted on a voluntary basis by a data
              subject to the data controller are stored for the purpose of
              dealing with the data subject's request. There is no transfer of
              this personal data to third parties notwithstanding sharing, if
              necessary to further the investigation of the request or to help
              resolve the query, with the LEGO data controller or customer
              support department.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              8. PERIOD FOR WHICH PERSONAL DATA ARE STORED
            </h2>
            <p className="mb-6">
              The data controller shall process and store the personal data of
              the data subject only for the period necessary to achieve the
              purpose of storage, or as long as is required by applicable US
              laws and regulations.
            </p>
            <p className="mb-6">
              If the storage purpose is fulfilled, or if a storage period
              prescribed by applicable US laws expires, the personal data are
              routinely blocked or erased in accordance with legal requirements.
              Data sent in connection with requests sent to us via our web
              application are deleted once the purpose of the communication is
              fulfilled. Our right to retain data for a longer period in
              accordance with legal retention periods or due to potential
              disputes remains unaffected.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              9. RIGHTS OF THE DATA SUBJECT
            </h2>

            <h3 className="text-xl font-semibold mb-2">A) RIGHT TO KNOW</h3>
            <p className="mb-4">
              Each data subject shall have the right, at no charge, to request
              that a business disclose what personal data it collects about the
              consumer, the sources from which the data is collected, the
              purpose for collecting or selling data, and the third parties with
              whom the business shares the data; and any other information
              required by relevant State and Federal statutes.
            </p>
            <p className="mb-4">
              Under the California Consumer Privacy Act (CCPA) and the
              California Privacy Rights Act (CPRA), California residents have
              the right to request that we disclose certain information to you
              about our collection and use of your personal data over the past
              12 months. Once we receive and confirm your verifiable consumer
              request, we will disclose to you:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>The categories of personal data we collected about you.</li>
              <li>
                The categories of sources for the personal data we collected
                about you.
              </li>
              <li>
                Our business or commercial purpose for collecting or selling
                that personal data.
              </li>
              <li>
                The categories of third parties with whom we share that personal
                data.
              </li>
              <li>
                The specific pieces of personal data we collected about you.
              </li>
              <li>
                If we sold or disclosed your personal data for a business
                purpose.
              </li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">B) RIGHT TO ACCESS</h3>
            <p className="mb-4">
              Each data subject shall have the right to request a copy of the
              specific personal data collected about them over the past 12
              months.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              C) RIGHT TO RECTIFICATION
            </h3>
            <p className="mb-4">
              Data subjects shall have the right to request the correction of
              their inaccurate personal information held by the business.
            </p>

            <h3 className="text-xl font-semibold mb-2">D) RIGHT TO DELETION</h3>
            <p className="mb-4">
              Each data subject shall have the right to request the deletion of
              personal data collected from them, with certain exceptions.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              E) RIGHT TO OPT-OUT OF SALE OR SHARING
            </h3>
            <p className="mb-4">
              Data subjects shall have the right to opt-out of the sale or
              sharing of their personal data by a business. Businesses must
              provide a clear and conspicuous link titled "Do Not Sell or Share
              My Personal Information" on their website homepage.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              F) RIGHT TO NON-DISCRIMINATION
            </h3>
            <p className="mb-4">
              Data subjects shall have the right not to receive discriminatory
              treatment by the business for exercising their privacy rights.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              G) RIGHT TO LIMIT USE AND DISCLOSURE OF SENSITIVE PERSONAL
              INFORMATION
            </h3>
            <p className="mb-4">
              Data subjects shall have the right to limit the use and disclosure
              of their sensitive personal information to that which is necessary
              to perform the services or provide the goods reasonably expected
              by an average data subject.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              H) RIGHT TO CORRECTION
            </h3>
            <p className="mb-4">
              Data subjects have the right to request correction of their
              inaccurate personal information maintained by the business.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              I) RIGHT TO LODGE A COMPLAINT
            </h3>
            <p className="mb-4">
              Data subjects have the right to lodge a complaint with the
              appropriate state authority if they believe their rights have been
              violated.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              10. LEGAL BASIS FOR THE PROCESSING
            </h2>
            <p className="mb-6">
              Our legal basis for processing personal data is in compliance with
              applicable US privacy laws, including but not limited to the
              California Consumer Privacy Act (CCPA) and the California Privacy
              Rights Act (CPRA).
            </p>
            <ul className="list-disc pl-6 mb-6">
              <li>
                Consent: We may process your personal data based on your
                consent, such as when you agree to participate in the LEGO Brick
                Take Back Program.
              </li>
              <li>
                Contractual Necessity: We may process personal data as necessary
                to fulfill our contractual obligations to you, for example, when
                you use our web application or contact us via our contact form.
              </li>
              <li>
                Legal Obligations: We may process personal data to comply with
                legal requirements.
              </li>
              <li>
                Legitimate Interests: We may process your personal data where it
                is in our legitimate interests to do so, such as to improve our
                web application and make it more user-friendly, provided that
                these interests are not overridden by your privacy rights
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">
              11. EXISTENCE OF AUTOMATED DECISION-MAKING, PROFILING
            </h2>
            <p className="mb-6">
              We do not use your data for profiling purposes or automatic
              decision-making.
            </p>
          </div>
        ) : (
          <div className="max-w-prose mx-auto">
            <h1 className="text-3xl font-bold mb-8">Datenschutzerklärung</h1>

            <p className="mb-6">Letzte Änderung: 15. Juli 2024</p>

            <p className="mb-6">
              Das LEGO® Brick Take Back Programm wird im Namen und im Auftrag
              der LEGO Group von der Pentatonic Ltd. durchgeführt. Siehe{" "}
              <Link
                to={t("urls.terms_and_conditions")}
                className="text-blue cursor-pointer"
              >
                allgemeine Geschäftsbedingungen
              </Link>
              .
            </p>

            <p className="mb-6">
              Datenschutz und Privatsphäre haben für Pentatonic Ltd. und die
              LEGO Gruppe höchste Priorität. Daher möchten wir Sie gemäß den
              geltenden Datenschutzbestimmungen darüber informieren, welche
              personenbezogenen Daten beim Besuch unserer Webanwendung verwendet
              werden und welche Rechte Sie als Datenschutzbetroffene haben.
            </p>

            <p className="mb-6">
              Pentatonic Ltd, ein Unternehmen mit Sitz im Vereinigten
              Königreich, hat die Pentatonic GmbH, ein Unternehmen mit Sitz in
              Berlin, Deutschland (Handelsregister HRB199313 B), als
              Datenverantwortlichen für alle Datenverarbeitungsaktivitäten in
              Bezug auf Nutzer mit Sitz in der Europäischen Union benannt.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              1. PERSÖNLICHE DATEN
            </h2>
            <p className="mb-6">
              Diese Datenschutzerklärung basiert auf der europäischen
              Datenschutzgrundverordnung (DSGVO) und dem deutschen
              Bundesdatenschutzgesetz (BDSG).
            </p>
            <p className="mb-6">
              Gegenstand dieser Datenschutzrichtlinie sind personenbezogene
              Daten. Personenbezogene Daten sind alle Informationen, die sich
              auf eine identifizierte oder identifizierbare natürliche Person
              ("betroffene Person") beziehen. Als identifizierbar wird eine
              natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              oder zu einem oder mehreren besonderen Merkmalen identifiziert
              werden kann, die Ausdruck der physischen, physiologischen,
              genetischen, psychischen, wirtschaftlichen, kulturellen oder
              sozialen Identität dieser natürlichen Person sind.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              2. NAME UND ANSCHRIFT DES DATENVERANTWORTLICHEN
            </h2>
            <p className="mb-6">
              Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO),
              anderer in den Mitgliedstaaten der Europäischen Union geltender
              Datenschutzgesetze und sonstiger datenschutzrechtlicher
              Bestimmungen ist:
            </p>
            <p className="mb-6">
              Pentatonic GmbH
              <br />
              Greifswalder Straße 51
              <br />
              10405 Berlin
              <br />
              Deutschland
              <br />
              Telefon: +49 30 3395 6013
              <br />
              E-Mail:{" "}
              <a
                href="mailto:support@legobricktakeback.com"
                className="text-blue"
              >
                support@legobricktakeback.com
              </a>
              <br />
              Datenschutzbeauftragter ist: Philip Mossop
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              3. DATENAUSTAUSCH MIT DER LEGO GRUPPE
            </h2>
            <p className="mb-6">
              Um das LEGO Brick Take Back Programm effektiv zu verwalten und zu
              betreiben, werden bestimmte personenbezogene Daten, die über
              unsere Webanwendung erhoben werden, an die LEGO Gruppe und ihre
              Tochtergesellschaften weitergegeben. Diese Weitergabe von Daten
              ist für die Bearbeitung Ihrer Anfragen, die Verwaltung des
              Programms und die Bereitstellung von Kundensupport unerlässlich.
              Zu den geteilten Daten gehören u. a. Kontaktinformationen,
              Informationen zum Take Back-Programm, Einzelheiten zu Anfragen,
              Adobe Analytics-Daten, die bei aktivierten Cookies erfasst werden,
              sowie alle anderen relevanten Informationen, die für den
              effektiven Betrieb des Programms erforderlich sind. Persönlich
              identifizierbare Informationen werden nicht zwischen Pentatonic
              und verbundenen Unternehmen der LEGO Gruppe weitergegeben.
            </p>
            <p className="mb-6">
              Die LEGO Gruppe behandelt Ihre persönlichen Daten in
              Übereinstimmung mit den geltenden Datenschutzgesetzen,
              einschließlich der DSGVO. Weitere Einzelheiten über die
              Verarbeitung personenbezogener Daten durch die LEGO Gruppe finden
              Sie in den in Deutschland geltenden Datenschutzbestimmungen der
              LEGO Gruppe, die unter{" "}
              <a
                href="https://lego.com/de-de/legal/notices-and-policies/privacy-policy"
                className="text-blue"
              >
                lego.com/de-de/legal/notices-and-policies/privacy-policy
              </a>
            </p>

            <h2 className="text-2xl font-semibold mb-4">4. COOKIES</h2>
            <p className="mb-6">
              Cookies sind kleine Datendateien, die Ihr Browser auf Ihrem
              Computer oder Gerät ablegt. Ein Cookie selbst enthält oder sammelt
              keine Informationen. Wenn es jedoch von einem Server über einen
              Webbrowser gelesen wird, kann es einer Website oder einer
              Webanwendung helfen, einen benutzerfreundlicheren Dienst zu
              erbringen - zum Beispiel, um sich Nutzerkontodaten zu merken.
            </p>
            <p className="mb-6">
              Für weitere Informationen über Cookies und deren Verwaltung lesen
              Sie bitte unsere vollständige{" "}
              <Link
                to={t("urls.cookie_policy")}
                className="text-blue cursor-pointer"
              >
                Cookie-Richtlinie
              </Link>{" "}
              und Cookie-Erklärung. oder klicken Sie auf Ihre
              Cookie-Einstellungen auf{" "}
              <a href="https://legobricktakeback.com" className="text-blue">
                https://legobricktakeback.com
              </a>
              .
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              5. ERFASSUNG VON ALLGEMEINEN DATEN UND INFORMATIONEN
            </h2>
            <p className="mb-6">
              Die Internetseite des LEGO Brick Take Back Programm erfasst mit
              jedem Aufruf der Internetseite durch eine betroffene Person oder
              ein automatisiertes System eine Reihe von allgemeinen Daten und
              Informationen. Diese allgemeinen Daten und Informationen werden in
              den Server-Logdateien gespeichert. Erfasst werden können (1) die
              verwendeten Browsertypen und -versionen, (2) das vom zugreifenden
              System verwendete Betriebssystem, (3) die Webanwendung, von der
              aus ein zugreifendes System unsere Webanwendung erreicht
              (sogenannte Referrer), (4) die Unterwebseiten, (5) Datum und
              Uhrzeit des Zugriffs auf die Internetseite, (6) eine
              Internetprotokolladresse (IP-Adresse) und (7) alle anderen
              ähnlichen Daten und Informationen, die im Falle von Angriffen auf
              unsere informationstechnologischen Systeme verwendet werden
              können.
            </p>
            <p className="mb-6">
              Bei der Nutzung dieser allgemeinen Daten und Informationen zieht
              die Pentatonic GmbH keine Rückschlüsse auf die betroffene Person.
              Diese Informationen werden vielmehr benötigt, um (1) die Inhalte
              unserer Internetseite korrekt auszuliefern, (2) die Inhalte
              unserer Internetseite sowie die Werbung für diese zu optimieren
              und (3) um Strafverfolgungsbehörden im Falle eines Cyberangriffes
              die zur Strafverfolgung notwendigen Informationen bereitzustellen.
              Diese anonym erhobenen Daten und Informationen werden durch die
              Pentatonic GmbH daher einerseits statistisch und ferner mit dem
              Ziel ausgewertet, den Datenschutz und die Datensicherheit in
              unserem Unternehmen zu erhöhen, um letztlich ein optimales
              Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
              sicherzustellen. Die anonymen Daten der Server-Logfiles werden
              getrennt von allen durch eine betroffene Person angegebenen
              personenbezogenen Daten gespeichert.
            </p>

            <h2 className="text-2xl font-semibold mb-4">6. ADOBE ANALYTICS</h2>
            <p className="mb-6">
              Auf dieser Webanwendung setzen wir Adobe Analytics ein. Adobe
              Analytics ist ein Webanalysedienst, der von Adobe Systems Software
              Ireland Limited bereitgestellt wird. Unter Webanalyse versteht man
              das Sammeln, Erfassen und Analysieren von Daten über das Verhalten
              der Besucher von Websites. Ein Webanalysedienst sammelt Daten
              darüber, von welcher Webanwendung eine Person gekommen ist (der
              sogenannte Referrer), welche Unterseiten besucht wurden oder wie
              oft und wie lange eine Unterseite angesehen wurde. Web-Analysen
              werden hauptsächlich zur Optimierung einer Web-Anwendung und zur
              Durchführung einer Kosten-Nutzen-Analyse von Internet-Werbung
              eingesetzt.
            </p>
            <p className="mb-6">
              Für die Webanalyse durch Adobe Analytics verwendet der für die
              Verarbeitung Verantwortliche die Anwendung "anonymizeIp". Die
              IP-Adresse des Internetanschlusses der betroffenen Person wird von
              Adobe gekürzt und anonymisiert, wenn der Zugriff auf unsere
              Webseiten aus einem Mitgliedstaat der Europäischen Union oder
              einem anderen Vertragsstaat des Abkommens über den Europäischen
              Wirtschaftsraum erfolgt.
            </p>
            <p className="mb-6">
              Der Zweck der Adobe Analytics-Komponente ist die Analyse des
              Datenverkehrs auf unserer Website. Adobe verwendet die gesammelten
              Daten und Informationen unter anderem, um die Nutzung unserer
              Webanwendung auszuwerten und um Online-Reports zu erstellen, die
              die Aktivitäten auf unseren Websites aufzeigen, und um weitere mit
              der Nutzung unserer Internetseite verbundene Dienstleistungen für
              uns zu erbringen.
            </p>
            <p className="mb-6">
              Adobe Analytics setzt ein Cookie auf dem informationstechnischen
              System der betroffenen Person. Die Definition von Cookies wird
              oben erläutert. Durch das Setzen des Cookies wird Adobe in die
              Lage versetzt, die Nutzung unserer Internetseite zu analysieren.
              Ihr Internetbrowser übermittelt bei jedem Besuch automatisch Daten
              über die Komponente Adobe Analytics zum Zwecke der Online-Werbung
              und der Abrechnung von Provisionen an Adobe. Adobe erhält Kenntnis
              von personenbezogenen Daten, wie z.B. der IP-Adresse der
              betroffenen Person, die Adobe u.a. dazu dienen, die Herkunft der
              Besucher und Klicks nachzuvollziehen und anschließend
              Provisionsabrechnungen zu erstellen.
            </p>
            <p className="mb-6">
              In dem Cookie werden personenbezogene Daten gespeichert, wie z.B.
              die Zugriffszeit, der Ort, von dem aus der Zugriff erfolgte, und
              die Häufigkeit der Besuche der betroffenen Person auf unserer
              Webanwendung. Bei jedem Besuch unserer Internetseite werden solche
              personenbezogenen Daten, einschließlich der IP-Adresse des von der
              betroffenen Person genutzten Internetzugangs, an Adobe
              übermittelt. Diese personenbezogenen Daten werden von Adobe
              gespeichert. Adobe kann diese durch das technische Verfahren
              erhobenen personenbezogenen Daten an Dritte weitergeben.
            </p>
            <p className="mb-6">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Webanwendung, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Adobe Analytics ein
              Cookie auf dem informationstechnologischen System der betroffenen
              Person setzt. Zudem können von Adobe Analytics bereits gesetzte
              Cookies jederzeit über einen Internetbrowser oder andere
              Softwareprogramme gelöscht werden.
            </p>
            <p className="mb-6">
              Darüber hinaus kann die betroffene Person einer Erfassung der
              durch Adobe Analytics erzeugten und auf eine Nutzung dieser
              Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten
              durch Adobe widersprechen. Zu diesem Zweck muss die betroffene
              Person ein Browser-Add-on unter
              <a
                className="text-blue"
                href="https://www.adobe.com/privacy/opt-out.html"
              >
                https://www.adobe.com/privacy/opt-out.html
              </a>{" "}
              herunterladen und installieren. Dieses Browser-Add-on weist Adobe
              Analytics über ein JavaScript an, dass keine Daten und
              Informationen über den Besuch von Internetseiten an Adobe
              Analytics übermittelt werden dürfen. Die Installation des
              Browser-Add-ons wird von Adobe als Widerspruch gewertet. Wird das
              informationstechnische System der betroffenen Person zu einem
              späteren Zeitpunkt gelöscht, formatiert oder neu installiert, muss
              die betroffene Person die Browser-Add-Ons erneut installieren, um
              Adobe Analytics zu deaktivieren. Wurde das Browser-Add-on von der
              betroffenen Person oder einer anderen Person, die ihrem
              Kompetenzbereich zuzurechnen ist, deinstalliert oder deaktiviert,
              kann die Neuinstallation oder Reaktivierung des Browser-Add-ons
              veranlasst werden.
            </p>
            <p className="mb-6">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Adobe können unter{" "}
              <a
                className="text-blue"
                href="https://www.adobe.com/privacy.html"
              >
                https://www.adobe.com/privacy.html
              </a>{" "}
              und unter{" "}
              <a
                className="text-blue"
                href="https://www.adobe.com/legal/terms.html"
              >
                https://www.adobe.com/legal/terms.html
              </a>{" "}
              abgerufen werden. Adobe Analytics wird unter{" "}
              <a
                className="text-blue"
                href="https://www.adobe.com/analytics/adobe-analytics.html"
              >
                https://www.adobe.com/analytics/adobe-analytics.html
              </a>{" "}
              näher erläutert.
            </p>

            <h2 className="text-2xl font-semibold mb-4">7. KONTAKT</h2>
            <p className="mb-6">
              Die betroffene Person kann sich an den Datenverantwortlichen
              wenden, indem sie eine E-Mail an{" "}
              <a
                href="mailto:support@legobricktakeback.com"
                className="text-blue"
              >
                support@legobricktakeback.com
              </a>
              schickt. Nimmt eine betroffene Person per E-Mail Kontakt mit dem
              Datenverantwortlichen auf, werden die von der betroffenen Person
              übermittelten personenbezogenen Daten automatisch gespeichert.
              Solche auf freiwilliger Basis von einer betroffenen Person an den
              Datenverantwortlichen übermittelten personenbezogenen Daten werden
              zum Zwecke der Bearbeitung der Anfrage der betroffenen Person
              gespeichert. Eine Weitergabe dieser personenbezogenen Daten an
              Dritte findet nicht statt, auch wenn eine Weitergabe an den für
              die Datenverarbeitung Verantwortlichen oder die
              Kundendienstabteilung der LEGO Gruppe zur weiteren Bearbeitung der
              Anfrage oder zur Lösung des Problems erforderlich ist.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              8. DAUER DER SPEICHERUNG DER PERSONENBEZOGENEN DATEN
            </h2>
            <p className="mb-6">
              Der für die Verarbeitung Verantwortliche verarbeitet und speichert
              personenbezogene Daten der betroffenen Person nur für den
              Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich
              ist oder sofern dies durch den Europäischen Richtlinien- und
              Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
              Vorschriften, welchen der für die Verarbeitung Verantwortliche
              unterliegt, vorgesehen wurde, "z.B. aufgrund von Verpflichtungen
              zur Finanzberichterstattung".
            </p>
            <p className="mb-6">
              Ist der Speicherungszweck erfüllt oder läuft eine vom europäischen
              Gesetzgeber oder einem anderen zuständigen Gesetzgeber
              vorgeschriebene Speicherfrist ab, werden die personenbezogenen
              Daten routinemäßig und entsprechend den gesetzlichen Vorschriften
              gesperrt oder gelöscht. Daten, die im Zusammenhang mit Anfragen an
              uns über unsere Webanwendung übermittelt wurden, werden gelöscht,
              sobald der Zweck der Mitteilung erfüllt ist. Unser Recht zur
              längeren Aufbewahrung der Daten nach Maßgabe gesetzlicher
              Aufbewahrungsfristen oder aufgrund etwaiger Streitigkeiten bleibt
              hiervon unberührt.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              9. RECHTE DES BETROFFENEN
            </h2>
            <h3 className="text-xl font-semibold mb-2">
              A) RECHT AUF BESTÄTIGUNG
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, von uns eine Bestätigung
              darüber zu erhalten, ob sie betreffende personenbezogene Daten
              verarbeitet werden.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              B) RECHT AUF AUSKUNFT
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, von uns jederzeit
              unentgeltlich Auskunft über die zu ihrer Person gespeicherten
              Daten und eine Kopie dieser Auskunft zu erhalten.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              C) RECHT AUF BERICHTIGUNG
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, von dem
              Datenverantwortlichen ohne unangemessene Verzögerung die
              Berichtigung sie betreffender unrichtiger personenbezogener Daten
              zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung
              hat die betroffene Person das Recht, die Vervollständigung
              unvollständiger personenbezogener Daten - auch mittels einer
              ergänzenden Erklärung - zu verlangen.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              D) RECHT AUF LÖSCHUNG (RECHT AUF VERGESSENWERDEN)
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, von dem
              Datenverantwortlichen zu verlangen, dass sie betreffende
              personenbezogene Daten unverzüglich gelöscht werden, und der für
              die Verarbeitung Verantwortliche ist verpflichtet,
              personenbezogene Daten unverzüglich zu löschen, sofern einer der
              folgenden Gründe zutrifft und die Verarbeitung nicht erforderlich
              ist:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Die personenbezogenen Daten sind für die Zwecke, für die sie
                erhoben oder anderweitig verarbeitet wurden, nicht mehr
                erforderlich.
              </li>
              <li>
                Die betroffene Person widerruft ihre Einwilligung, auf die sich
                die Verarbeitung gemäß Artikel 6 Absatz 1 Buchstabe a der DSGVO
                oder Artikel 9 Absatz 2 Buchstabe a der DSGVO stützt, und es
                liegt kein anderer Rechtsgrund für die Verarbeitung vor.
              </li>
              <li>
                Die betroffene Person legt gemäß Artikel 21 Absatz 1 der DSGVO
                Widerspruch gegen die Verarbeitung ein, und es liegen keine
                vorrangigen berechtigten Gründe für die Verarbeitung vor, oder
                die betroffene Person legt gemäß Artikel 21 Absatz 2 der DSGVO
                Widerspruch gegen die Verarbeitung ein.
              </li>
              <li>
                Die personenbezogenen Daten sind unrechtmäßig verarbeitet
                worden.
              </li>
              <li>
                Die personenbezogenen Daten müssen gelöscht werden, um einer
                rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht
                der Mitgliedstaaten nachzukommen, dem der für die Verarbeitung
                Verantwortliche unterliegt.
              </li>
              <li>
                Die personenbezogenen Daten wurden im Zusammenhang mit dem
                Angebot von Diensten der Informationsgesellschaft gemäß Artikel
                8 Absatz 1 der DSGVO erhoben.
              </li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">
              E) RECHT AUF EINSCHRÄNKUNG DER VERARBEITUNG
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, von dem
              Datenverantwortlichen die Einschränkung der Verarbeitung zu
              verlangen, wenn einer der folgenden Punkte zutrifft:
            </p>

            <h3 className="text-xl font-semibold mb-2">
              F) RECHT AUF DATENÜBERTRAGBARKEIT
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, die sie betreffenden
              personenbezogenen Daten, die sie einem Verantwortlichen
              bereitgestellt hat, in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten. Sie hat das Recht, diese
              Daten einem anderen Verantwortlichen ohne Behinderung durch den
              Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
              wurden, zu übermitteln, sofern die Verarbeitung auf einer
              Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a der DSGVO oder
              Artikel 9 Absatz 2 Buchstabe a der DSGVO beruht, oder auf einem
              Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b der
              Datenschutz-Grundverordnung beruht und die Verarbeitung mithilfe
              automatisierter Verfahren erfolgt. Dieses Recht gilt nicht für
              eine Verarbeitung, die für die Wahrnehmung einer Aufgabe
              erforderlich ist, die im öffentlichen Interesse liegt oder in
              Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
              übertragen wurde.
            </p>
            <p className="mb-4">
              Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
              Datenübertragbarkeit gemäß Artikel 20 Absatz 1 DSGVO das Recht, zu
              erwirken, dass personenbezogene Daten direkt von einem
              Verantwortlichen an einen anderen übermittelt werden, soweit dies
              technisch machbar ist und sofern dies nicht die Rechte und
              Freiheiten anderer Personen beeinträchtigt.
            </p>
            <p className="mb-4">
              Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich
              die betroffene Person jederzeit an einen Mitarbeiter der
              Pentatonic GmbH wenden.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              G) RECHT AUF WIDERSPRUCH
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, aus Gründen, die sich aus
              ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung sie betreffender personenbezogener Daten, die
              aufgrund von Artikel 6 Absatz 1 Buchstabe e oder f DSGVO erfolgt,
              Widerspruch einzulegen. Dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              H) RECHT AUF WIDERRUF DER DATENSCHUTZRECHTLICHEN EINWILLIGUNG
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, ihre Einwilligung zur
              Verarbeitung der sie betreffenden personenbezogenen Daten
              jederzeit zu widerrufen.
            </p>

            <h3 className="text-xl font-semibold mb-2">
              I) RECHT AUF EINREICHUNG EINER BESCHWERDE
            </h3>
            <p className="mb-4">
              Jede betroffene Person hat das Recht, eine Beschwerde bei einer
              Aufsichtsbehörde einzureichen.
            </p>

            <h2 className="text-2xl font-semibold mb-4">
              10. BESTEHEN EINER AUTOMATISIERTEN ENTSCHEIDUNGSFINDUNG, PROFILING
            </h2>
            <p className="mb-6">
              Wir verwenden Ihre Daten nicht für Profiling-Zwecke oder
              automatische Entscheidungsfindung.
            </p>
          </div>
        )}
      </div>
      <Footer />
      {/* <ImpressumFooter /> */}
    </div>
  );
}
