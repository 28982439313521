import React from "react";
import LoadingGIF from "assets/loading.gif";

export default function LoadingSpinner({ isLoading = "Loading..." }) {
  return (
    <div className="flex flex-col gap-8 justify-center items-center h-[calc(100vh-81px)]">
      <img src={LoadingGIF} alt="" className="w-[72px]" />
      <h1 className="font-semibold text-6xl">{isLoading}</h1>
    </div>
  );
}
