import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import {
  useLocation,
  useOutletContext,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import BrickIcon from "assets/brickIcon.svg";
import HeadIcon from "assets/headIcon.svg";
import WeightEstimation from "components/weight-estimation";
import "material-symbols/outlined.css";
import {
  localizeDecimalSeparator,
  getQueryParams,
  useCurrentLocation,
} from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { calculateEstimate } from "lib/calculateEstimate";
import { useCreateTakeback } from "api/use-takebacks";
import AuthContext from "contexts/AuthContext";
import { TAKEBACK_METHOD_NAME, TAKEBACK_ID } from "consts/query-params";
import storeLocations from "data/stores.json";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

export default function SummaryPage() {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useOutletContext();

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  //get current location settings
  const { location: currentLocation } = useCurrentLocation();

  const isMailMethod = queryParams[TAKEBACK_METHOD_NAME] === "mail";

  const parsedWeight = parseFloat(queryParams.w).toFixed(1);
  const weightEstimate =
    currentLocation?.locale !== "en-US"
      ? parsedWeight.toString().replace(".", ",")
      : parsedWeight;

  const estimate = calculateEstimate(queryParams.w, i18n.language.slice(-2));
  const unit = `${t("scale.weight")}`;

  //reset right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual(currentLocation.locale === "de-DE" ? "euro" : "dollar");
  }, [setSidebarVisual, currentLocation]);

  const matchingAddress = storeLocations.find(
    (location) => location.value === queryParams[TAKEBACK_METHOD_NAME]
  )?.address;
  const addressString = matchingAddress
    ? matchingAddress
    : t("trade-in.summary.address_not_found");
  const navigate = useNavigate();
  const onCreate = ({ sys_id }) => {
    setIsLoading(null);
    navigate(
      `${t("urls.trade_in_next_steps")}?${createSearchParams({
        ...queryParams,
        [TAKEBACK_ID]: sys_id,
      }).toString()}`
    );
  };

  const { mutate: createTakeback } = useCreateTakeback({ onCreate });

  // Auth
  const { user } = useContext(AuthContext);

  let userStreet = user.street || "";

  // Replace \n with ", " unless it's at the end
  userStreet = userStreet.replace(/\n(?!$)/g, ", ");

  // Trim any trailing ", "
  userStreet = userStreet.replace(/, $/, "");

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  const methodName =
    queryParams.p === "giftcard"
      ? t("trade-in.summary.giftcardTitle")
      : t("trade-in.summary.cashTitle");

  return (
    <FormContainer
      onSubmit={() => {
        setIsLoading(t("trade-in.summary.loading_message"));
        createTakeback({
          uom: t("scale.weight"),
          weight: queryParams.w,
          paymentType: queryParams.p,
          returnMethod: queryParams[TAKEBACK_METHOD_NAME],
          store: isMailMethod ? null : queryParams[TAKEBACK_METHOD_NAME],
        });
      }}
      submitLabel={t("ui.button_submit")}
      secondaryLabel={t("ui.button_back")}
      secondaryHref={
        queryParams[TAKEBACK_METHOD_NAME] === "mail"
          ? t("urls.trade_in_terms")
          : t("urls.trade_in_payout_method")
      }
      secondaryParams={queryParams}
    >
      <ProgressBar step={7} />
      <h2 className="font-semibold text-6xl max-w-lg">
        {t("trade-in.summary.title")}
      </h2>
      <div className="flex flex-col">
        <div className="w-full flex md:border-b md:border-gray-300 py-6 pb-regular md:pb-x-large">
          <div className="w-16">
            <img src={BrickIcon} alt="" />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="text-2xl font-bold">
              {t("trade-in.summary.trade_in_title")}
            </div>
            <div className="text-secondary">
              {t("trade-in.summary.trade_in_weight", {
                weight: weightEstimate,
                unit: unit,
              })}
            </div>
            <WeightEstimation
              className="mt-4 hidden md:flex"
              visual={queryParams.p === "giftcard" ? "giftcard" : "cash"}
              title={t("ui.estimate_part1")}
              locale={currentLocation.locale}
              singleValue={
                showPayment
                  ? queryParams.p === "giftcard"
                    ? localizeDecimalSeparator(estimate.gift, currentLocation)
                    : localizeDecimalSeparator(
                        estimate.payment,
                        currentLocation
                      )
                  : localizeDecimalSeparator(estimate.gift, currentLocation)
              }
              animate
              description={`${methodName}. ${t("ui.estimate_part2", {
                weight: weightEstimate,
                unit: unit,
              })}`}
            />
          </div>
        </div>
        <WeightEstimation
          className="mt-4 md:hidden"
          locale={currentLocation.locale}
          singleValue={
            showPayment
              ? queryParams.p === "giftcard"
                ? localizeDecimalSeparator(estimate.gift, currentLocation)
                : localizeDecimalSeparator(estimate.payment, currentLocation)
              : localizeDecimalSeparator(estimate.gift, currentLocation)
          }
          animate
          description={`${methodName}. ${t("ui.estimate_part2", {
            weight: weightEstimate,
            unit: unit,
          })}`}
          visual={queryParams.p === "giftcard" ? "giftcard" : "cash"}
        />
        <div className="w-full flex border-t mt-x-large md:mt-0 md:border-t-0 border-b border-gray-300 py-6">
          <div className="w-16">
            <img src={HeadIcon} alt="" />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="text-2xl font-bold">
              {t("trade-in.summary.your_details_title")}
            </div>
            <div className="text-secondary flex flex-col gap-1">
              <div>
                {user.first_name} {user.last_name}
              </div>
              <div>
                {userStreet && `${userStreet.trim()}, `}
                {user.city && `${user.city.trim()}, `}
                {user.state && `${user.state.trim()} `}
                {user.zip && `${user.zip.trim()} `}
              </div>
              <div>{user.email}</div>
            </div>
          </div>
        </div>
        <div className="w-full flex pt-6">
          <div className="w-16">
            <span
              className={`material-symbols-outlined scale-125 text-tertiary`}
            >
              {isMailMethod ? "package_2" : "storefront"}
            </span>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="text-2xl font-bold">
              {isMailMethod
                ? t("trade-in.summary.mail_in")
                : t("trade-in.summary.in_store_drop_in")}
            </div>
            {!isMailMethod && (
              <div className="text-secondary">
                {t("trade-in.summary.store_instructions")}
              </div>
            )}
            <div className="text-secondary flex flex-col gap-1">
              {isMailMethod ? (
                <Fragment>
                  {t("trade-in.summary.mail_in_instructions")}
                  <a
                    href={
                      currentLocation.locale === "de-DE"
                        ? "https://www.dhl.de/de/privatkunden/dhl-standorte-finden.html"
                        : "https://www.theupsstore.com/tools/find-a-store"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#016db8]"
                  >
                    {t("trade-in.summary.find_nearest_ups_location")}
                  </a>
                </Fragment>
              ) : (
                `${t("trade-in.summary.location")}: ${addressString}`
              )}
            </div>
          </div>
        </div>
      </div>
    </FormContainer>
  );
}
