import React, { createContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const { REACT_APP_API } = process.env;

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [authTokens, setAuthTokens] = useState(
    JSON.parse(localStorage.getItem("authTokens"))
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const logoutHandler = (redirectTo = "/") => {
    localStorage.removeItem("user");
    localStorage.removeItem("authTokens");
    window.location.href = redirectTo;
  };

  const accessRefreshSuccess = (data) => {
    const { access_token, refresh_token, user } = data;

    if (user) {
      localStorage.setItem(
        "user",
        JSON.stringify({ ...user, country: user.country?.toLowerCase() })
      );
      setUser(user);
    }

    localStorage.setItem(
      "authTokens",
      JSON.stringify({ access_token, refresh_token })
    );
    setAuthTokens({ access_token, refresh_token });
  };

  const { mutate: refreshMutation } = useMutation(
    async ({ refresh_token, username }) => {
      const response = await fetch(`${REACT_APP_API}/auth/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({ refresh_token, username }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Token refresh failed: ${errorText}`);
        logoutHandler();
      }

      return response.json();
    },
    {
      onSuccess: accessRefreshSuccess,
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const { mutate: loginMutation, error: loginError } = useMutation(
    async ({ username, password }) => {
      const response = await fetch(`${REACT_APP_API}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorText = t("login.password_alarm");
        throw new Error(`${errorText}`);
      }

      return response.json();
    },
    {
      onSuccess: accessRefreshSuccess,
    }
  );

  const { mutate: signupMutation, error: signupErrorObj } = useMutation(
    async ({
      firstName,
      lastName,
      email,
      password,
      addressLine1,
      addressLine2,
      addressCity,
      addressCountry,
      addressZip,
      addressState,
      takeback,
      isSubscribedToSurvey,
      isSubscribedToReminders,
      redirectTo,
    }) => {
      const response = await fetch(`${REACT_APP_API}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          addressLine1,
          addressLine2,
          addressCity,
          addressCountry,
          addressZip,
          addressState,
          takeback,
          isSubscribedToSurvey,
          isSubscribedToReminders,
          redirectTo: redirectTo || `${window.location.origin}/login`,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Signup failed: ${errorText}`);
      }

      return await response.json();
    },
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const signupError = signupErrorObj?.message || null;

  const { mutate: resendEmailMutation, error: resendEmailError } = useMutation(
    async ({ email, redirectTo }) => {
      const response = await fetch(`${REACT_APP_API}/auth/resend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({ email, redirectTo }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to resend verification email: ${errorText}`);
      }

      return response.json();
    },
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const loginHandler = (
    { username, password },
    { onSuccess = () => {} } = {},
    { onError = () => {} } = {}
  ) => {
    return loginMutation(
      { username, password },
      {
        onSuccess: (data) => {
          accessRefreshSuccess(data);
          onSuccess(data);
        },
        onError: (error) => {
          console.error(error);
          onError(error);
        },
      }
    );
  };

  const signupHandler = (
    payload,
    { onSuccess = () => {} } = {},
    { onError = () => {} } = {}
  ) => {
    return signupMutation(payload, {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        console.error(error);
        onError(error);
      },
    });
  };

  const resendEmailVerificationHandler = (
    { email, redirectTo },
    { onSuccess = () => {} } = {},
    { onError = () => {} } = {}
  ) => {
    return resendEmailMutation(
      { email, redirectTo },
      {
        onSuccess: (data) => {
          onSuccess(data);
        },
        onError: (error) => {
          console.error(error);
          onError(error);
        },
      }
    );
  };

  useEffect(() => {
    if (authTokens?.refresh_token && user?.email) {
      if (isInitialLoad) {
        refreshMutation({
          refresh_token: authTokens.refresh_token,
          username: user.email,
        });
        setIsInitialLoad(false);
      }

      const interval = setInterval(
        () => {
          refreshMutation({
            refresh_token: authTokens.refresh_token,
            username: user.email,
          });
        },
        15 * 60 * 1000
      );

      return () => clearInterval(interval);
    }
  }, [authTokens, refreshMutation, user, isInitialLoad]);

  return (
    <AuthContext.Provider
      value={{
        user,
        authTokens,
        logoutHandler,
        loginHandler,
        loginError,
        signupHandler,
        signupError,
        resendEmailVerificationHandler,
        resendEmailError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
