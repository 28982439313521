import ModalProvider from "../layouts/modal-provider";
import { Navigate } from "react-router-dom";
import * as pages from "../pages";
import TradeInForm from "../layouts/trade-in-form";
import StoreViewLayout from "../layouts/store-view";
import StoreSignupLayout from "../layouts/store-signup";
import PasswordLayout from "../layouts/password";
import { useTranslation } from "react-i18next";
import { ProtectedRoute } from "./ProtectedRoute";

export const useRoutes = (lang) => {
  const { t } = useTranslation();

  return [
    {
      element: <ModalProvider />,
      children: [
        {
          path: "/",
          element: <Navigate to={`/${lang}`} replace />,
        },
        {
          path: `/${lang}`,
          children: [
            { path: "", element: <pages.Landing /> },
            { path: "uat", element: <pages.Uat /> },
            { path: "impressum", element: <pages.Impressum /> },
            { path: t("urls.unsubscribe"), element: <pages.Unsubscribe /> },
            {
              path: t("urls.manage-email-preferences"),
              element: <pages.Unsubscribe />,
            },
            {
              path: t("urls.login"),
              element: <pages.Login />,
            },
            {
              path: t("urls.scale"),
              element: <pages.Scales />,
            },
            {
              path: t("urls.kiosk"),
              element: <pages.Kiosk />,
            },
            {
              path: t("urls.holding"),
              element: <pages.ComingSoon />,
            },
            {
              path: t("urls.my_trade_ins"),
              element: <pages.MyTradeIns />,
            },
            {
              path: t("urls.georestrict"),
              element: <pages.GeoRestrict />,
            },
            {
              path: t("urls.preparation_checklist"),
              element: <pages.PreperationChecklist />,
            },
            {
              path: t("urls.terms_and_conditions"),
              element: <pages.TermsConditionsPage />,
            },
            {
              path: t("urls.privacy_policy"),
              element: <pages.PrivacyPolicyPage />,
            },
            {
              path: t("urls.cookie_policy"),
              element: <pages.CookiePolicyPage />,
            },
            {
              path: t("urls.cookie_declaration"),
              element: <pages.CookieDeclarationPage />,
            },
            {
              path: t("urls.trade_in"),
              element: <TradeInForm />,
              children: [
                {
                  path: "",
                  element: <Navigate to={t("urls.trade_in_weight")} />,
                },
                {
                  path: t("urls.trade_in_weight"),
                  element: <pages.WeightPage />,
                },
                {
                  path: t("urls.trade_in_method"),
                  element: <pages.MethodPage />,
                },
                {
                  path: t("urls.trade_in_checklist"),
                  element: <pages.ChecklistPage />,
                },
                {
                  path: t("urls.trade_in_payout_method"),
                  element: <pages.PayoutMethodPage />,
                },
                {
                  path: t("urls.trade_in_email"),
                  element: <pages.EmailPage />,
                },
                {
                  path: t("urls.trade_in_sign_in"),
                  element: <pages.SignInPage />,
                },
                {
                  path: t("urls.trade_in_sign_up"),
                  element: <pages.SignUpPage />,
                },
                {
                  path: t("urls.trade_in_verification"),
                  element: <pages.VerificationPage />,
                },
                {
                  path: t("urls.trade_in_terms"),
                  element: <pages.TermsPage />,
                },
                {
                  path: t("urls.trade_in_summary"),
                  element: <pages.SummaryPage />,
                },
                {
                  path: t("urls.trade_in_next_steps"),
                  element: <pages.NextStepsPage />,
                },
                {
                  path: t("urls.trade_in_qr_code"),
                  element: <pages.OnlineQrCode />,
                },
              ],
            },
            {
              path: t("urls.store"),
              element: (
                <ProtectedRoute>
                  <StoreViewLayout />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: t("urls.store_schedule"),
                  element: <pages.Schedule />,
                },
                {
                  path: t("urls.store_trade_ins"),
                  element: <pages.TradeIns />,
                },
                {
                  path: t("urls.store_create_shipment"),
                  element: <pages.CreateShipmentForm />,
                },
                {
                  path: t("urls.store_package_qr_code"),
                  element: <pages.GeneratePackageQRCode />,
                },
                {
                  path: t("urls.store_shipping_label"),
                  element: <pages.ShipmentLabel />,
                },
                {
                  path: t("urls.store_confirm_weight"),
                  element: <pages.ConfirmWeight />,
                },
                {
                  path: t("urls.store_trade_in"),
                  element: <pages.CreateTradeIn />,
                },
                {
                  path: t("urls.store_trade_in_weight"),
                  element: <pages.TradeInWeight />,
                },
                {
                  path: t("urls.store_payout"),
                  element: <pages.StorePayout />,
                },
                {
                  path: t("urls.store_payout_confirm"),
                  element: <pages.StorePayoutConfirm />,
                },
                {
                  path: t("urls.store_terms"),
                  element: <pages.StoreTerms />,
                },
                {
                  path: t("urls.store_confirmation"),
                  element: <pages.StoreTradeIFinal />,
                },
                {
                  path: t("urls.store_check_in"),
                  element: <pages.CheckIn1 />,
                },
                {
                  path: t("urls.store_check_in_email"),
                  element: <pages.CheckInEmail />,
                },
                {
                  path: t("urls.store_check_in_tradeins_email"),
                  element: <pages.CheckInTakebacks />,
                },
                {
                  path: t("urls.store_trade_in_confirmation"),
                  element: <pages.TradeInSummary />,
                },
              ],
            },
            {
              path: t("urls.store_no_header"),
              element: <StoreViewLayout showHeader={false} />,
              children: [
                {
                  path: t("urls.store_check_in_qr"),
                  element: <pages.CheckInQr />,
                },
                {
                  path: t("urls.store_trade_in_start"),
                  element: <pages.TradeInQr />,
                },
                {
                  path: t("urls.store_trade_in_password"),
                  element: <pages.TradeInPassword />,
                },
                {
                  path: t("urls.signup_store_qr_entrance"),
                  element: <pages.InStoreQR />,
                },
              ],
            },
            {
              path: t("urls.signup_store"),
              element: <StoreSignupLayout />,
              children: [
                {
                  path: t("urls.signup_store_start"),
                  element: <pages.StoreChecklist />,
                },
                {
                  path: t("urls.signup_store_email"),
                  element: <pages.StoreEmail />,
                },
                {
                  path: t("urls.signup_store_sign_in"),
                  element: <pages.InStoreSignin />,
                },
                {
                  path: t("urls.signup_store_sign_in_exists"),
                  element: <pages.InStoreSigninExists />,
                },
                {
                  path: t("urls.signup_store_sign_up"),
                  element: <pages.InStoreSignup />,
                },
                {
                  path: t("urls.signup_store_email_confirm"),
                  element: <pages.InStoreEmailConfirm />,
                },
                {
                  path: t("urls.signup_store_confirmation"),
                  element: <pages.InStoreConfirmation />,
                },
                {
                  path: t("urls.signup_store_confirmation_exists"),
                  element: <pages.InStoreConfirmationExists />,
                },
              ],
            },
            {
              path: t("urls.password"),
              element: <PasswordLayout />,
              children: [
                {
                  path: t("urls.password_forgot"),
                  element: <pages.PasswordForgot />,
                },
                {
                  path: t("urls.password_update"),
                  element: <pages.PasswordUpdate />,
                },
              ],
            },
            {
              path: t("urls.warehouse"),
              element: <pages.ReceiveTakeback />,
            },
            {
              path: t("urls.warehouse_v2"),
              element: <pages.WarehousePackages />,
            },
            {
              path: `${t("urls.update_address")}/:email?`,
              element: <pages.UpdateAddress />,
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to={`/${lang}`} replace />,
        },
      ],
    },
  ];
};
