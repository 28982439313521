import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { Button } from "components/button";
import AuthContext from "contexts/AuthContext";
import { useCreateTakeback } from "api/use-takebacks";
import { useTranslation } from "react-i18next";
import { getQueryParams } from "lib/utils";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import LoadingSpinner from "components/loading-spinner";

export default function InStoreConfirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const { logoutHandler } = useContext(AuthContext);
  const [takebackId, setTakebackId] = useState(null);
  const [isCreatingTakeback, setIsCreatingTakeback] = useState(false);

  const handleSignOut = () => {
    logoutHandler();
    navigate(t("urls.signup_store_start"));
  };

  const {
    mutate: createTakeback,
    isLoading,
    isError,
  } = useCreateTakeback({
    onCreate: ({ sys_id }) => {
      setTakebackId(sys_id);
      setIsCreatingTakeback(false);
    },
  });

  useEffect(() => {
    if (!takebackId && !isCreatingTakeback) {
      setIsCreatingTakeback(true);
      createTakeback({
        uom: t("scale.weight"),
        weight: "0",
        ...(queryParams.p ? { paymentType: queryParams.p } : {}),
        returnMethod: queryParams[TAKEBACK_METHOD_NAME],
        store: queryParams[TAKEBACK_METHOD_NAME],
      });
    }
  }, [createTakeback, takebackId, isCreatingTakeback, queryParams, t]);

  if (isError) {
    return <div>Error creating takeback. Please try again.</div>;
  }

  if (!takebackId) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-between h-[calc(100vh-81px)] p-6">
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : (
        <div className="flex flex-col h-full items-center justify-center">
          <div className="bg-white flex flex-col my-auto items-center text-center px-large">
            <QRCode value={takebackId} size={200} />
            <h2 className="text-primary text-6xl mt-x-large">
              You created a trade-in
            </h2>
            <span className="text-tertiary text-base mt-regular">
              Present this QR code to the in-store associate to continue.
            </span>
          </div>
          <Button
            onClick={handleSignOut}
            variant="secondary"
            type="button"
            className="w-full bottom-0"
          >
            Sign Out
          </Button>
        </div>
      )}
    </div>
  );
}
