import React, { useState, useEffect } from "react";
import BrickIcon from "assets/brickIcon.svg";
import HeadIcon from "assets/headIcon.svg";
import "material-symbols/outlined.css";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useLocation } from "react-router-dom";
import StoreFormContainer from "components/store-form-container";
import { useTranslation } from "react-i18next";
import { useMyTakebacks } from "api/use-takebacks";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";

export default function TradeInSummary() {
  const now = new Date();
  const date = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
  const appointmentSet = useState(false);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const { data = [] } = useMyTakebacks();

  const filteredData = data.filter(
    ({ u_lego_return_type }) => u_lego_return_type !== "mail"
  );

  const [, setinputWeight] = useState(null);
  const [, setinputReturnType] = useState(null);

  useEffect(() => {
    if (filteredData.length > 0) {
      const inputItem = filteredData[0];
      setinputWeight(inputItem.u_lego_presort_weight);
      setinputReturnType(inputItem.u_lego_return_type);
    }
  }, [data, filteredData]);

  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME],
    "/store/trade-in"
  );

  const previouslySetWeight = queryParams.w;

  const updatedParams = { ...queryParams };

  const { t } = useTranslation();

  return (
    <StoreFormContainer
      submitParams={updatedParams}
      submitLabel={t("ui.button_continue")}
    >
      <div className="flex flex-grow flex-col px-6 py-4">
        <h2 className="text-black text-6xl">Trade In Confirmed</h2>
        <div className="flex flex-col">
          <div className="w-full flex border-b border-[#e4e4e4] pt-12 pb-6">
            <div className="w-16">
              <img src={HeadIcon} alt="" />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="font-medium text-2xl">Customer</div>
              <div className="text-secondary flex flex-col gap-1">
                <span className="font-semibold pt-2">Name</span>
                <span>Philip Mossop</span>
                <span className="font-semibold pt-2">Home Address</span>
                <div>2028 Shadwell Way, Lawrenceville, GA 30043</div>
                <span className="font-semibold pt-2">Email Address</span>
                <div>philip@pentatonic.com</div>
              </div>
            </div>
          </div>
          <div className="w-full flex border-b border-[#e4e4e4] pt-2 pb-6">
            <div className="w-16 pt-6">
              <img src={BrickIcon} alt="" />
            </div>
            <div className="flex flex-col gap-2 w-full py-6">
              <div className="font-medium text-2xl">Trade-In Information</div>
              <span className="font-semibold pt-2">Created</span>
              <span>{date}</span>
              <span className="font-semibold pt-2">Quantity</span>
              <div className="text-secondary">{previouslySetWeight}</div>
              <span className="font-semibold pt-2">Value</span>
              <span className="text-secondary">{previouslySetWeight}</span>
              <span className="font-semibold pt-2">Payment method</span>
              <span className="text-secondary">e-Gift Card</span>
            </div>
          </div>
          {appointmentSet === true && (
            <div className="w-full flex pt-6">
              <div className="w-16">
                <span
                  className={`material-symbols-outlined scale-125 text-[#757575]`}
                >
                  storefront
                </span>
              </div>
              <div className="flex flex-col gap-2 w-full">
                <div className="font-medium">In Store Appointment</div>
                <div className="text-secondary flex flex-col gap-1">
                  <span className="font-semibold pt-2">Date and time</span>
                  <span className="text-secondary">12/12/2021 12:00 PM</span>
                  <span className="font-semibold pt-2">Location</span>
                  <span className="text-secondary">
                    Location: LEGO Store Sugarloaf Mills, 5900 Sugarloaf
                    Parkway, Lawrenceville, GA 30043
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </StoreFormContainer>
  );
}
