import { useState, useEffect } from "react";
import Checklist from "components/checklist";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useLocation, useOutletContext } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation } from "react-i18next";
import { useCurrentLocation } from "lib/utils";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";

export default function ChecklistPage() {
  const [allChecked, setAllChecked] = useState(false);

  // translation
  const { t } = useTranslation();

  //reset right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual("bricks");
  }, [setSidebarVisual]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME],
    t("urls.trade_in_weight")
  );

  const [skipMethodPage, setSkipMethodPage] = useState(false);

  const { location: currentLocation } = useCurrentLocation();

  // contitional navigation based on location
  useEffect(() => {
    if (currentLocation) {
      if (
        currentLocation?.takebackMethod?.includes("mail") &&
        currentLocation?.takebackMethod?.length === 1
      ) {
        setSkipMethodPage(true);
      } else {
        setSkipMethodPage(false);
      }
    } else {
      setSkipMethodPage(false);
    }
  }, [queryParams, currentLocation]);

  const backDestination = skipMethodPage
    ? `${t("urls.trade_in_weight")}`
    : `${t("urls.trade_in_method")}`;

  //checklist items
  const checklistItems = [
    {
      name: "brickBasedToys",
      label: t("trade-in.checklist.brick_based_toys"),
    },
    {
      name: "bricksSeparated",
      label: t("trade-in.checklist.bricks_separated"),
    },
    {
      name: "removedElectronics",
      label: t("trade-in.checklist.removed_electronics"),
    },
    {
      name: "removedDuplo",
      label: t("trade-in.checklist.removed_duplo"),
    },
    {
      name: "removedNonLego",
      label: t("trade-in.checklist.removed_non_lego"),
    },
  ];

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      submitHref={`${t("urls.trade_in_payout_method")}`}
      submitParams={queryParams}
      submitDisabled={!allChecked}
      secondaryLabel={t("ui.button_back")}
      secondaryHref={backDestination}
      secondaryParams={queryParams}
    >
      <ProgressBar step={3} />
      <div className="flex flex-col gap-regular">
        <h2 className="text-primary text-6xl">
          {t("trade-in.checklist.title")}
        </h2>
        <p className="text-tertiary text-2xl">
          {t("trade-in.checklist.description")}
        </p>
      </div>
      <div className="flex flex-col gap-x-large">
        <h3 className="text-2xl font-bold">
          {t("trade-in.checklist.checklist_title")}
        </h3>
        <Checklist
          setAllChecked={setAllChecked}
          checklistItems={checklistItems}
        />
      </div>
    </FormContainer>
  );
}
