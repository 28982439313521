import { useState, useContext } from "react";
import { motion } from "framer-motion";
import logo from "assets/logo.jpg";
import CloseIcon from "assets/icons/close.svg";
import { useTranslation } from "react-i18next";
// import { Address } from "components/address";
import { Input } from "components/input";
import AuthContext from "contexts/AuthContext";
import { Button } from "components/button";
import LockIcon from "assets/icons/lock.svg";
import Mastercard from "assets/Mastercard.svg";
import Payall from "assets/Payall.svg";
import { Label } from "components/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
} from "components/select";
import prefCountryCodes from "data/pref-country-codes.json";
import otherCountryCodes from "data/other-country-codes.json";
import InputValidation from "components/input-validation";
import { useUpdateUser } from "api/use-user";
import Checklist from "components/checklist";
import { useUpdateTakeback } from "api/use-takebacks";

export default function PayallModal({
  setModalOpen,
  caseId,
  setPayallCompleted,
}) {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [firstName, setFirstName] = useState(`${user?.first_name || ""}`);
  const [lastName, setLastName] = useState(`${user?.last_name || ""}`);
  const [iban, setIban] = useState(user.u_lego_seller_iban);
  const [telephone, setTelephone] = useState("");
  const [countryCodeWithName, setCountryCodeWithName] = useState("");
  const [ibanError, setIbanError] = useState("");
  const [nameError, setNameError] = useState("");
  const countryCode = countryCodeWithName?.split(".")[0];
  const phoneWithCode = `${countryCode}${telephone}`;

  //checkbox states
  const [boxChecked, setBoxChecked] = useState(false);

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: (data) => {
      console.log("User updated successfully:", data);
      setIbanError("");
    },
    onError: (error) => {
      console.error("Error updating user:", error);
    },
  });

  const { mutate: updateTakeback } = useUpdateTakeback();

  const ibanRegex = /^DE\d{20}$/;
  const nameRegex = /^[A-Za-zäöüßÄÖÜ-]+$/;

  const handleCompleteClick = () => {
    if (!ibanRegex.test(iban)) {
      return setIbanError(t("ui.invalid_iban"));
    }

    if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      return setNameError(t("ui.invalid_german_name"));
    }

    updateTakeback({
      caseId,
      u_lego_account_holder_first_name: firstName,
      u_lego_account_holder_last_name: lastName,
      u_lego_seller_iban: iban,
    });

    updateUser({
      u_lego_seller_iban: iban,
      mobile_phone: phoneWithCode,
    });

    setModalOpen(false);
    setPayallCompleted(true);
  };

  const handleIbanChange = (e) => {
    const value = e.target.value;
    // Remove all empty spaces
    const ibanWithoutSpaces = value.replace(/\s+/g, "");
    setIban(ibanWithoutSpaces);
  };

  const handleTelephoneChange = (e) => {
    const value = e.target.value;
    setTelephone(value);
  };

  //checkbox for accurate data
  const checklistItems = [
    { name: "accurate", label: t("my_trade_ins.modal_checkbox") },
  ];

  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-50">
      <motion.div
        className="bg-[#000000b6] w-full h-full fixed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={() => setModalOpen(false)}
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
        className="bg-white rounded-xl mt-[5vh] w-[min(500px,90vw)] mx-auto overflow-scroll h-[90vh] p-6 relative"
      >
        <div
          className="absolute top-6 right-6 cursor-pointer"
          onClick={() => setModalOpen(false)}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <div className="flex justify-center">
          <img src={logo} alt="LEGO Logo" className="h-12" />
        </div>
        <h2 className="text-5xl mt-6 w-full text-center">
          {t("my_trade_ins.modal_title")}
        </h2>
        <div className="w-full mt-1 text-center text-black-400 text-base">
          {t("my_trade_ins.subtitle")}
        </div>
        <div className="flex flex-col gap-x-large mt-8">
          <div className="flex flex-col sm:grid grid-cols-2 gap-x-x-large">
            <Input
              label={t("ui.input_first_name")}
              type="text"
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              label={t("ui.input_last_name")}
              type="text"
              id="lastName"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          {nameError && <InputValidation errorMessage={nameError} />}
          <div className="mb-2">
            <Input
              label={t("ui.input_iban")}
              type="text"
              id="iban"
              name="iban"
              className=""
              value={iban}
              onChange={handleIbanChange}
              placeholder="DEXXXXXXXXXXXXXXXXXXXX"
            />
            {ibanError && <InputValidation errorMessage={ibanError} />}
          </div>
          {/* <Address address={address} setAddress={setAddress} /> */}
          <div>
            <Label htmlFor="countryCode">
              {t("ui.input_telephone_number")}
            </Label>
            <div className="flex gap-x-large">
              <div className="w-[160px]">
                <Select
                  id="countryCode"
                  onValueChange={(value) => setCountryCodeWithName(() => value)}
                  value={countryCodeWithName || "SELECT"}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem key="SELECT" value="SELECT" disabled>
                        {t("ui.input_country_code")}
                      </SelectItem>
                      {prefCountryCodes.map((countryCode) => (
                        <SelectItem
                          key={countryCode.name}
                          value={`${countryCode.dial_code}.${countryCode.name}`}
                        >
                          {countryCode.dial_code} {countryCode.name}
                        </SelectItem>
                      ))}
                      <SelectSeparator />
                      {otherCountryCodes.map((countryCode) => (
                        <SelectItem
                          key={countryCode.name}
                          value={`${countryCode.dial_code}.${countryCode.name}`}
                        >
                          {countryCode.dial_code} {countryCode.name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <Input
                type="number"
                pattern="^\d*$"
                id="telephone"
                name="telephone"
                className="mb-2 flex-1"
                value={telephone}
                onChange={handleTelephoneChange}
              />
            </div>
          </div>
          <Checklist
            checklistItems={checklistItems}
            setAllChecked={setBoxChecked}
            className="-my-[8px]"
          />
        </div>
        <div className="flex flex-col gap-large mt-xxx-large">
          <Button
            className="w-full"
            onClick={handleCompleteClick}
            disabled={!iban || !telephone || !countryCode || !boxChecked}
          >
            {t("my_trade_ins.modal_button_complete")}
          </Button>
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => setModalOpen(false)}
          >
            {t("my_trade_ins.modal_button_cancel")}
          </Button>
          <div className="flex w-full justify-center gap-2">
            <img src={LockIcon} alt="Lock Icon" />
            <img src={Mastercard} alt="Mastercard Logo" />
            <img src={Payall} alt="Payall Logo" />
          </div>
        </div>
      </motion.div>
    </div>
  );
}
