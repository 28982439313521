import React, { useState } from "react";
import packagesSvg from "./svgs/packages.svg";

import { PackageLookup } from "./PackageLookup";

export const WarehousePackages = () => {
  const [started, setStarted] = useState(false);

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center font-sans p-4 bg-white">
          <div className="flex flex-col items-center justify-center">
            <img src={packagesSvg} style={{ width: "10rem" }} alt="Packages" />
            <h1 className="font-semibold text-6xl max-w-lg">Packages</h1>
            <p className="text-tertiary text-2xl">
              Check-in or process packages
            </p>

            <button
              className="rounded-lg text-4xl bg-blue text-white px-x-large py-regular mt-large"
              onClick={() => {
                setStarted(true);
              }}
            >
              Start
            </button>
          </div>
        </div>
      </div>
      {started && <PackageLookup onClose={() => setStarted(false)} />}
    </div>
  );
};
