import Cash from "assets/cashBig.jpg";
import GiftCard from "assets/giftcardBig.jpg";
import useLocalizedCurrencyValue from "hooks/useLocalizedCurrencyValue";
import { calculateEstimate } from "lib/calculateEstimate";
import { useCurrentLocation } from "lib/utils";
import { Trans, useTranslation } from "react-i18next";

export default function RewardOverviewLanding({ handleScrollTo }) {
  const { location: currentLocation } = useCurrentLocation();
  const pricePerUnit = calculateEstimate(1, currentLocation?.country);
  const pricePerUnitGift = useLocalizedCurrencyValue(pricePerUnit.gift);
  const pricePerUnitCash = useLocalizedCurrencyValue(pricePerUnit.payment);

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-x-large w-full items-center mt-xxx-large">
      <div className="font-bold text-4xl mb-large">
        {t("ui.reward_overview.title")}
      </div>
      <div className="flex gap-xx-large justify-center">
        <div className="flex items-center flex-col text-center flex-nowrap gap-[16px]">
          <img
            src={GiftCard}
            alt="Cash Icon"
            className="w-[72px] aspect-[16/10] rounded-xs"
          />
          <div className="flex flex-col gap-x-small">
            <div className="font-bold">
              {t("ui.reward_overview.giftcard_title")}
            </div>
            <div className="text-sm text-black-300 font-light">
              <Trans
                i18nKey="ui.reward_overview.giftcard_value_landing"
                values={{ value: pricePerUnitGift }}
                components={{
                  1: (
                    <span
                      onClick={(e) => handleScrollTo(e, "whatWeAccept", 80)}
                      className="cursor-pointer text-blue"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[16px] h-[1px] bg-black-300/50"></div>
          <div>{t("ui.reward_overview.or")}</div>
          <div className="w-[16px] h-[1px] bg-black-300/50"></div>
        </div>
        <div className="flex items-center flex-col text-center flex-nowrap gap-[16px]">
          <img
            src={Cash}
            alt="Cash Icon"
            className="w-[72px] aspect-[16/10] rounded-xs"
          />
          <div className="flex flex-col gap-x-small">
            <div className="font-bold">
              {t("ui.reward_overview.cash_title")}
            </div>
            <div className="text-sm text-black-300 font-light">
              {/* {t("ui.reward_overview.cash_value_landing", {
                value: pricePerUnitCash,
              })} */}
              <Trans
                i18nKey="ui.reward_overview.cash_value_landing"
                values={{ value: pricePerUnitCash }}
                components={{
                  1: (
                    <span
                      onClick={(e) => handleScrollTo(e, "whatWeAccept", 80)}
                      className="cursor-pointer text-blue"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
