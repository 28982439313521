import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./fonts.css";

import { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";

import { I18nRouter } from "./router/I18nRouter";

const queryClient = new QueryClient();

const App = () => {
  const { i18n, t } = useTranslation();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Helmet>
          <html lang={i18n.language} />
          <style type="text/css">
            {`.cky-consent-container a { color: #1863dc !important; text-decoration: underline; }`}
          </style>
          <meta name="description" content={t("meta.description")} />
          <meta property="og:description" content={t("meta.description")} />
          <meta
            property="twitter:description"
            content={t("meta.description")}
          />
        </Helmet>
        <I18nRouter />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
