import React, { useEffect, useMemo, useContext, useState } from "react";
import WeightEstimation from "components/weight-estimation";
import egift_card from "assets/egift_card.svg";
import egift_cardWEBP from "assets/egift_cardWEBP.webp";
import StoreFormContainer from "components/store-form-container";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateEstimate } from "lib/calculateEstimate";
import { Input } from "components/input";
import { useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import { Address } from "../../components/address";
import { useTakebackById } from "api/use-takebacks";
import { useCurrentLocation } from "lib/utils";
import { TAKEBACK_DISBURSEMENT_NAME } from "consts/query-params";

const { REACT_APP_API } = process.env;

export default function StorePayoutConfirm() {
  const associateUser = JSON.parse(localStorage.getItem("user") || {});
  const navigate = useNavigate();
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.access_token;
  const location = useLocation();
  const queryParams = useMemo(
    () => getQueryParams(location.search),
    [location.search]
  );

  // translation
  const { t } = useTranslation();
  const unit = `${t("scale.weight")}`;

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [address, setAddress] = useState();
  const allInputsFilled = firstName && lastName && address;
  const payoutMethod = queryParams[TAKEBACK_DISBURSEMENT_NAME];

  const previouslySetWeight = queryParams.w;
  const parsedWeight = parseFloat(previouslySetWeight).toFixed(1);
  const { location: currentLocation } = useCurrentLocation();
  const estimate = calculateEstimate(
    previouslySetWeight,
    currentLocation?.country
  );

  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_DISBURSEMENT_NAME],
    "/store/trade-in"
  );

  const updatedParams = { ...queryParams };

  const takeback = useTakebackById({ id: queryParams.id });

  const handleSubmit = async () => {
    const response = await fetch(`${REACT_APP_API}/user`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        email: associateUser.email,
        userEmail: takeback.consumer.email,
        first_name: firstName,
        last_name: lastName,
        ...address,
        addressCountry: address.addressCountry.toUpperCase(),
      }),
    });

    if (response.ok) {
      const response = await fetch(
        `${REACT_APP_API}/takeback/${queryParams.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            inStoreVerified: true,
            u_lego_postsort_weight: queryParams.w,
            paymentType: updatedParams[TAKEBACK_DISBURSEMENT_NAME],
          }),
        }
      );

      if (response.ok) {
        const searchParams = new URLSearchParams(updatedParams).toString();
        navigate(`${t("urls.store_confirmation")}?${searchParams}`);
      } else {
        alert("Please try again");
      }
    } else {
      alert("Please try again");
    }
  };

  useEffect(() => {
    if (takeback) {
      const [addressLine1, addressLine2] = (
        takeback.consumer.street || ``
      ).split(`\n`);

      setFirstName(takeback.consumer.firstName || ``);
      setLastName(takeback.consumer.lastName || ``);
      setAddress({
        addressLine1: addressLine1 || ``,
        addressLine2: addressLine2 || ``,
        addressCity: takeback.consumer.city || ``,
        addressCountry:
          takeback.consumer.country || queryParams.l?.substring(0, 2) || ``,
        addressZip: takeback.consumer.zip || ``,
        addressState: takeback.consumer.state || ``,
      });
    }
  }, [queryParams.l, takeback]);

  if (!address) {
    return null;
  }

  return (
    <StoreFormContainer
      submitLabel="Confirm Trade In"
      onSubmit={handleSubmit}
      submitDisabled={!allInputsFilled}
      secondaryLabel="Back"
      secondaryHref={t("urls.store_payout")}
      secondaryParams={updatedParams}
    >
      <div className="flex flex-col flex-grow py-4">
        <div className="flex flex-col mr-auto">
          <h1 className="text-6xl font-bold">
            Hey, {takeback.consumer.firstName}
          </h1>
          <div className="text-tertiary text-2xl">
            Please confirm your details.
          </div>
        </div>
        <div>
          {payoutMethod === "giftcard" ? (
            <WeightEstimation
              description={`LEGO® e-Gift Card. Based on ${parsedWeight} ${unit}`}
              singleValue={estimate.gift}
              className=""
              image={egift_card}
              imgSource={egift_cardWEBP}
              locale="en-US"
              animate
            />
          ) : (
            <WeightEstimation
              description={`Digital cash. Based on ${previouslySetWeight}${unit}`}
              className=""
              singleValue={estimate.payment}
              image={egift_card}
              imgSource={egift_cardWEBP}
              locale="en-US"
              animate
            />
          )}
        </div>
        <div>
          <div className="flex flex-col">
            <div className="flex items-center justify-between space-x-6 mt-8">
              <div className="flex w-1/2 flex-col">
                <Input
                  type="text"
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex w-1/2 flex-col">
                <Input
                  type="text"
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <Address
                currentLocation={currentLocation}
                address={address}
                setAddress={setAddress}
              />
            </div>
          </div>
        </div>
      </div>
    </StoreFormContainer>
  );
}
