import { useTranslation } from "react-i18next";

export const useUnprotectedRoutes = () => {
  const { t } = useTranslation();

  return [
    "",
    "/",
    `${t("urls.landing")}/`,
    t("urls.login"),
    t("urls.sign_up"),
    t("urls.holding"),
    t("urls.landing"),
    t("urls.trade_in_weight"),
    t("urls.trade_in_method"),
    t("urls.trade_in_checklist"),
    t("urls.trade_in_payout_method"),
    t("urls.trade_in_email"),
    t("urls.trade_in_sign_in"),
    t("urls.trade_in_sign_up"),
    t("urls.trade_in_summary"),
    t("urls.scale"),
    t("urls.kiosk"),
    t("urls.signup_store_start"),
    t("urls.signup_store_qr_entrance"),
    t("urls.signup_store_email_confirm"),
    t("urls.signup_store_confirmation_exists"),
    t("urls.signup_store_confirmation"),
    t("urls.signup_store_email"),
    t("urls.signup_store_sign_up"),
    t("urls.signup_store_sign_in"),
    t("urls.signup_store_sign_in_exists"),
    t("urls.trade_in_verification"),
    t("urls.georestrict"),
    t("urls.preparation_checklist"),
    t("urls.password_forgot"),
    t("urls.password_update"),
    t("urls.terms_and_conditions"),
    t("urls.privacy_policy"),
    t("urls.cookie_policy"),
    t("urls.cookie_declaration"),
    t("urls.store_trade_in_password"),
    "/en-US/uat",
    "/de-DE/impressum",
  ];
};
