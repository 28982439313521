import React, { useContext, useEffect, useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { Input } from "components/input";
import { getQueryParams } from "lib/utils";
import { checkPasswordCriteria, isValidEmail } from "lib/utils";
import AuthContext from "contexts/AuthContext";
import { PasswordValidation } from "components/password-validation";
import PasswordInput from "components/password-input";
import { Trans, useTranslation } from "react-i18next";
import { Address } from "components/address";
import { TAKEBACK_METHOD_NAME, COUNTRY_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
import { Link } from "react-router-dom";
import "material-symbols/outlined.css";
import SingleCheckbox from "components/checkbox";

export default function InStoreSignup() {
  const { t } = useTranslation();
  const { signupHandler, signupError } = useContext(AuthContext);
  if (signupError) {
    console.error("signupError", signupError);
  }
  const [email, setEmail] = useState(localStorage.getItem("emailInput") || "");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAlarm, setEmailAlarm] = useState(false);
  const [passwordAlarm, setPasswordAlarm] = useState(null);
  const navigate = useNavigate();

  const [isSubscribedToMailReminders, setIsSubscribedToMailReminders] =
    useState(false);
  const [isSubscribedToSurvey, setIsSubscribedToSurvey] = useState(false);
  const [isNotInterested, setIsNotInterseted] = useState(false);

  useEffect(() => {
    if (isNotInterested) {
      setIsSubscribedToMailReminders(false);
      setIsSubscribedToSurvey(false);
    }
  }, [isNotInterested]);

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const { location: currentLocation } = useCurrentLocation();

  const [address, setAddress] = useState({
    addressLine1: ``,
    addressLine2: ``,
    addressCity: ``,
    addressCountry: queryParams[COUNTRY_NAME] || "US",
    addressZip: ``,
    addressState: ``,
  });

  useEffect(() => {
    if (queryParams && queryParams.e) {
      setEmail(queryParams.e);
    }
  }, [queryParams]);

  // Handle input change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    localStorage.setItem("emailInput", value);
    setEmailAlarm(!isValidEmail(value));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordAlarm(!checkPasswordCriteria(value));
  };

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);

  const handleSubmit = () => {
    const formData = {
      ...(firstName?.trim() && { firstName: firstName.trim() }),
      ...(lastName?.trim() && { lastName: lastName.trim() }),
      ...(email?.trim() && { email: email.trim() }),
      ...(password?.trim() && { password: password.trim() }),
      ...(address?.addressLine1?.trim() && {
        addressLine1: address.addressLine1.trim(),
      }),
      ...(address?.addressLine2?.trim() && {
        addressLine2: address.addressLine2.trim(),
      }),
      ...(address?.addressCity?.trim() && {
        addressCity: address.addressCity.trim(),
      }),
      ...(address?.addressZip?.trim() && {
        addressZip: address.addressZip.trim(),
      }),
      ...(address?.addressState?.trim() && {
        addressState: address.addressState.trim(),
      }),
      ...(address?.addressCountry?.trim() && {
        addressCountry: address.addressCountry.toUpperCase().trim(),
      }),
      takeback: {
        uom: t("scale.weight"),
        store: queryParams[TAKEBACK_METHOD_NAME],
      },
      isSubscribedToSurvey,
      isSubscribedToReminders: isSubscribedToMailReminders,
      redirectTo: `${window.location.origin}${t(`urls.signup_store_sign_in`)}${
        location.search
      }`,
    };

    signupHandler(formData, {
      onSuccess: () => {
        const navigateOptions = {
          pathname: t("urls.signup_store_email_confirm"),
        };
        navigateOptions.search = createSearchParams(queryParams).toString();
        navigate(navigateOptions);
        navigate(0);
      },
    });
  };

  const allFieldsValid =
    email &&
    password &&
    !passwordAlarm &&
    !emailAlarm &&
    firstName &&
    lastName &&
    address.addressLine1 &&
    address.addressCity &&
    address.addressZip &&
    (address.addressCountry !== "US" || address.addressState) &&
    address.addressCountry;

  const updatedParams = { ...queryParams };

  return (
    <FormContainer
      submitLabel="Submit"
      onSubmit={handleSubmit}
      submitDisabled={!allFieldsValid}
      submitParams={updatedParams}
      secondaryParams={updatedParams}
      secondaryLabel="Back"
      secondaryHref={`${t("urls.signup_store_email")}`}
    >
      <ProgressBar step={2} stepsNum={3} />
      <h2 className="font-semibold text-6xl max-w-lg">
        Create an Account to Continue
      </h2>
      <div className="w-full flex flex-col gap-x-large">
        <div>
          <Input
            name="email"
            onChange={handleEmailChange}
            value={email}
            label="Email Address"
            type="email"
          />
          {emailAlarm && (
            <div className="text-secondary text-sm mt-regular">
              <span className="material-symbols-outlined scale-75 text-red align-bottom mr-1">
                error
              </span>
              Please enter a valid email.
            </div>
          )}
        </div>
        <div>
          <PasswordInput
            name="password"
            label="Password"
            type="password"
            onChange={handlePasswordChange}
            value={password}
          />
          <PasswordValidation passwordAlarm={passwordAlarm} />
        </div>
        <div className="grid grid-cols-1 gap-x-large">
          <Input
            name="firstName"
            label="First Name"
            type="text"
            onChange={handleFirstNameChange}
            value={firstName}
          />
          <Input
            name="lastName"
            label="Last Name"
            type="text"
            onChange={handleLastNameChange}
            value={lastName}
          />
          <Address
            address={address}
            setAddress={setAddress}
            currentLocation={currentLocation}
          />
        </div>

        <div className="flex flex-col gap-x-large">
          <div>
            <p className="text-xl">{t("sign-up-email-preferences.title")}</p>
            <p className="text-sm text-secondary">
              {t("sign-up-email-preferences.subtitle")}
            </p>
          </div>
          <p className="text-sm text-secondary -mb-medium">
            {t("email-preferences.selectOne")}
          </p>
          <p className="text-secondary text-base">
            <SingleCheckbox
              disabled={isNotInterested}
              label={t("sign-up-email-preferences.marketingOption")}
              checked={isSubscribedToSurvey}
              setChecked={setIsSubscribedToSurvey}
            />
          </p>
          <p className="text-secondary text-base">
            <SingleCheckbox
              disabled={isNotInterested}
              label={t("sign-up-email-preferences.updatesOption")}
              checked={isSubscribedToMailReminders}
              setChecked={setIsSubscribedToMailReminders}
            />
          </p>
          <p className="text-secondary text-base">
            <SingleCheckbox
              label={t("sign-up-email-preferences.selectAll")}
              checked={isNotInterested}
              setChecked={setIsNotInterseted}
            />
          </p>
        </div>
        <div>
          <Trans
            i18nKey="trade-in.sign_up.statement"
            components={{
              1: (
                <Link
                  to={t("urls.privacy_policy")}
                  className="text-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                ></Link>
              ),
            }}
          />
        </div>
      </div>
    </FormContainer>
  );
}
