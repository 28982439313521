import React from "react";
import { Header } from "components/header";
import BrickIcon from "assets/brickIcon.svg";
import HeadIcon from "assets/headIcon.svg";
import "material-symbols/outlined.css";
import StoreFormContainer from "components/store-form-container";
import { getQueryParams } from "lib/utils";
import { useLocation } from "react-router-dom";
import { calculateEstimate } from "lib/calculateEstimate";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useTakebackById } from "../../api/use-takebacks";

const buildRewardText = (paymentType) => {
  let text = `Not yet selected`;

  if (paymentType) {
    text = paymentType === `giftcard` ? `LEGO® e-Gift Card` : `Digital Cash`;
  }

  return text;
};

export default function TradeInQr() {
  const { t, i18n } = useTranslation();
  const currencySymbol = t("currency.symbol");
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const id = queryParams.id;
  const destination = t("urls.store_trade_in");
  const takeback = useTakebackById({ id });

  if (!takeback) {
    return null;
  }

  const weight = takeback?.u_lego_presort_weight;
  const parsedWeight = parseFloat(weight).toFixed(1);
  const estimate = calculateEstimate(weight, i18n.language.slice(-2));
  const unit = `${t("scale.weight")}`;

  const rewardText = buildRewardText(takeback?.u_lego_payment_type);

  return (
    <StoreFormContainer
      submitParams={queryParams}
      submitLabel={takeback.u_lego_payment_issued_at === "" && "Start Trade-In"}
      secondaryLabel={t("ui.button_cancel")}
      submitHref={destination}
      secondaryHref={t(`urls.store_check_in`)}
      secondaryParams={queryParams}
    >
      <Header />
      <div className="flex flex-grow flex-col py-4">
        <h2 className="font-semibold text-6xl max-w-lg">Trade In</h2>
        <div className="flex flex-col">
          <div className="w-full flex border-b border-[#e4e4e4] pt-12 pb-6">
            <div className="w-16">
              <img src={HeadIcon} alt="" />
            </div>
            <div className="flex flex-col w-full">
              <div className="font-medium text-2xl mb-large">Customer</div>
              <div className="flex flex-col">
                <span className="text-primary font-semibold mb-x-small">
                  Name
                </span>
                <span className="text-secondary">
                  {takeback.consumer.firstName.trim()}{" "}
                  {takeback.consumer.lastName.trim()}
                </span>
                <span className="text-primary font-semibold mb-x-small pt-large">
                  Home Address
                </span>
                <div className="text-secondary">
                  {takeback.consumer.street.trim()},{" "}
                  {takeback.consumer.city.trim()},{" "}
                  {takeback.consumer.zip.trim()}
                </div>
                <span className="text-primary font-semibold mb-x-small pt-large">
                  Email Address
                </span>
                <div className="text-secondary">
                  {takeback.consumer.email.trim()}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex pt-2 pb-6">
            <div className="w-16 pt-6">
              <img src={BrickIcon} alt="" />
            </div>
            <div className="flex flex-col w-full py-6">
              <div className="font-medium text-2xl mb-large">
                Trade-In Information
              </div>
              <span className="text-primary font-semibold mb-x-small">
                Created
              </span>
              <span className="text-secondary">
                {format(
                  takeback.sys_created_on
                    ? new Date(takeback.sys_created_on)
                    : new Date(),
                  `MM/dd/yy HH:mm`
                )}
              </span>
              {takeback.u_lego_payment_issued_at !== "" && (
                <>
                  <span className="text-primary font-semibold mt-4 mb-x-small">
                    Completed
                  </span>
                  <span className="text-secondary">
                    {format(
                      takeback.u_lego_payment_issued_at
                        ? new Date(takeback.u_lego_payment_issued_at)
                        : new Date(),
                      `MM/dd/yy HH:mm`
                    )}
                  </span>
                </>
              )}
              {Boolean(weight) && (
                <>
                  <span className="text-primary font-semibold pt-large mb-x-small">
                    Estimated Quantity
                  </span>
                  <div className="text-secondary">
                    {parsedWeight} {unit}
                  </div>
                </>
              )}
              {!isNaN(estimate.payment) && estimate.gift !== "0.00" && (
                <>
                  <span className="text-primary font-semibold pt-large mb-x-small">
                    Estimated Value
                  </span>
                  <span className="text-secondary">
                    {currencySymbol}
                    {estimate.gift}
                  </span>
                </>
              )}
              <span className="text-primary font-semibold pt-large mb-x-small">
                Preferred Reward Method
              </span>
              <span className="text-secondary">{rewardText}</span>
            </div>
          </div>
        </div>
      </div>
    </StoreFormContainer>
  );
}
