import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useTakebackByShippingTracking,
  useUpdateTakeback,
} from "api/use-takebacks";
import AuthContext from "contexts/AuthContext";
import { Step0 } from "./step-0";
import { Step1 } from "./step-1";
import { Step2 } from "./step-2";
import { Step3 } from "./step-3";
import { Step4 } from "./step-4";
import { Step5 } from "./step-5";
import { Step6 } from "./step-6";
import { Step7 } from "./step-7";
import { formatSnowDate, useCurrentLocation } from "lib/utils";

const convertWeight = (uom) => (weight) => {
  if (uom !== "lbs") return weight;
  const weightInKg = (weight * 2.20462).toFixed(2);
  return Math.ceil(weightInKg * 10) / 10;
};

export const validateTrackingNumber = (trackingNumber, type) => {
  const validators = {
    DHL: () => /^[0-9]{12}$/.test(trackingNumber) && !isNaN(trackingNumber),
    SHIP: () => trackingNumber.startsWith("SHI"),
    UPS: () =>
      trackingNumber.startsWith("1Z") &&
      /^(1Z[a-zA-Z0-9]{16}|[Tt][0-9]{9}|[0-9]{12})$/.test(trackingNumber),
  };

  return validators[type]();
};

export const ReceiveTakeback = () => {
  const { t } = useTranslation();
  const { authTokens } = useContext(AuthContext);
  const [step, setStep] = useState(0);
  const [packageWeight, setPackageWeight] = useState("");
  const [controlContainerWeight, setControlContainerWeight] = useState("");
  const [, setIsMailIn] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [sortedItems, setSortedItems] = useState({
    generalNonConform: 0,
    assembledNonConform: 0,
    goodQualityLego: 0,
  });
  const [scannedResult, setScannedResult] = useState({});
  const [packagePictures, setPackagePictures] = useState([]);
  const [contentPictures, setContentPictures] = useState([]);
  const [nonConformingPictures, setNonconformingPictures] = useState([]);
  const [trackingNumberError, setTrackingNumberError] = useState("");
  const [codeType, setCodeType] = useState(false);

  const [isRejected, setIsRejected] = useState(false);
  const [isGreenLight, setIsGreenLight] = useState(false);

  const handleNextStep = () => {
    if (codeType === "In-store") {
      alert("In-store shipment not ready for processing");
    } else {
      setStep(step + 1);
    }
  };
  const handlePreviousStep = () => setStep(step - 1);

  const { data, isLoading: isLoadingTakeback } = useTakebackByShippingTracking({
    upsTracking:
      validateTrackingNumber(trackingNumber, "UPS") && trackingNumber,
    dhlTracking:
      validateTrackingNumber(trackingNumber, "DHL") && trackingNumber,
    shippingCode:
      validateTrackingNumber(trackingNumber, "SHIP") && trackingNumber,
    onSuccess: (data) => {
      setTrackingNumberError(data?.length === 1 ? "" : "No takeback found");
    },
  });

  const currentTakeback = data?.length && data[0];
  const estimatedWeight = currentTakeback?.u_lego_presort_weight || 0;

  const handleDeleteImage = (setter) => (index) => {
    setter((images) => images.filter((_, i) => i !== index));
  };

  const { location: currentLocation } = useCurrentLocation();

  const [saveError, setSaveError] = useState("");
  const { mutate: updateTakeback, isLoading: isLoadingSubmit } =
    useUpdateTakeback({
      onError: (error) => {
        setSaveError(error);
      },
    });

  useEffect(() => {
    const newNonConform =
      packageWeight -
      controlContainerWeight -
      (Math.max(sortedItems.assembledNonConform, controlContainerWeight) -
        controlContainerWeight) -
      (Math.max(sortedItems.goodQualityLego, controlContainerWeight) -
        controlContainerWeight);

    if (sortedItems.generalNonConform !== newNonConform) {
      setSortedItems({
        ...sortedItems,
        generalNonConform: newNonConform,
      });
    }
  }, [sortedItems, packageWeight, controlContainerWeight]);

  useEffect(() => {
    if (currentTakeback) {
      const Tmax = Math.max(0.25 * estimatedWeight, 1);
      setIsGreenLight(
        Number(sortedItems.goodQualityLego) -
          controlContainerWeight +
          Number(Tmax) >=
          estimatedWeight
      );
    }
  }, [estimatedWeight, sortedItems, currentTakeback, controlContainerWeight]);

  const handleFinalSubmit = async () => {
    const weightConverter = convertWeight(currentTakeback.u_lego_weight_unit);
    const acceptableLegoWeight = weightConverter(
      Math.max(sortedItems.goodQualityLego, controlContainerWeight) -
        controlContainerWeight +
        Math.max(sortedItems.assembledNonConform, controlContainerWeight) -
        controlContainerWeight
    );

    const paymentWeight = Math.max(
      acceptableLegoWeight,
      currentTakeback?.u_lego_presort_weight
    );

    const payload = {
      caseId: currentTakeback.number,
      uom: t("scale.weight"),
      accessToken: authTokens.access_token,
      u_lego_presort_warehouse_weight: weightConverter(
        packageWeight - controlContainerWeight
      ),
      u_lego_general_nonconform_weight: weightConverter(
        sortedItems.generalNonConform
      ),
      u_lego_assembled_nonconform_weight: weightConverter(
        Math.max(sortedItems.assembledNonConform, controlContainerWeight) -
          controlContainerWeight
      ),
      u_lego_postsort_weight: weightConverter(
        Math.max(sortedItems.goodQualityLego, controlContainerWeight) -
          controlContainerWeight
      ),
      u_lego_payment_weight: paymentWeight,
      packageImages: packagePictures,
      contentImages: contentPictures,
      nonConformingImages: nonConformingPictures,
      u_lego_verified_at: !isRejected && formatSnowDate(new Date()),
      legoValue: data[0].u_lego_incentive_ratio * paymentWeight,
      currencyCode: currentLocation.country === "US" ? "USD" : "EUR",
    };

    if (isRejected) {
      payload.u_lego_escalated = true;
    }

    if (!isGreenLight) {
      payload.u_lego_flagged_for_review = true;
    }

    updateTakeback(payload, {
      onSuccess: () => {
        step > 1 && window.location.reload();
      },
    });
  };

  const handleRejectTakeback = () => {
    setIsRejected(true);
    setStep(7);
  };

  useEffect(() => {
    const { text = "" } = scannedResult;

    if (validateTrackingNumber(text, "DHL")) {
      setIsMailIn(true);
      setTrackingNumber(text);
      setCodeType("DHL");
    }

    if (validateTrackingNumber(text, "UPS")) {
      setIsMailIn(true);
      setTrackingNumber(text);
      setCodeType("UPS");
    }

    if (validateTrackingNumber(text, "SHIP")) {
      setIsMailIn(false);
      setCodeType("In-store");
      setTrackingNumber(text);
    }

    if (
      !validateTrackingNumber(text, "UPS") &&
      !validateTrackingNumber(text, "DHL") &&
      !validateTrackingNumber(text, "SHIP")
    ) {
      setCodeType(false);
    }
  }, [scannedResult, trackingNumber]);

  const totalContentWeight =
    parseFloat(sortedItems.generalNonConform) +
    parseFloat(
      Math.max(sortedItems.assembledNonConform, controlContainerWeight) -
        controlContainerWeight
    ) +
    parseFloat(
      Math.max(sortedItems.goodQualityLego, controlContainerWeight) -
        controlContainerWeight
    );

  const isWeightValid =
    totalContentWeight <= parseFloat(packageWeight - controlContainerWeight);

  return (
    <div className="flex items-center justify-center bg-yellow min-h-screen">
      <div className="text-center font-sans p-4 bg-white">
        <h1 className="text-2xl font-bold mb-4">Process takeback</h1>
        {step === 0 && (
          <>
            <Step0
              currentTakeback={currentTakeback}
              codeType={codeType}
              setScannedResult={setScannedResult}
              scannedResult={scannedResult}
              isLoadingTakeback={isLoadingTakeback}
              isValidTracking={!trackingNumberError}
              updateTakeback={updateTakeback}
              handleNextStep={handleNextStep}
            />
            {scannedResult.text && !isLoadingTakeback && trackingNumberError}
          </>
        )}
        {step === 1 && (
          <Step1
            setPackagePictures={setPackagePictures}
            packagePictures={packagePictures}
            handlePreviousStep={handlePreviousStep}
            handleDeletePackagePicture={handleDeleteImage(setPackagePictures)}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 2 && (
          <Step2
            controlContainerWeight={controlContainerWeight}
            setControlContainerWeight={setControlContainerWeight}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 3 && (
          <Step3
            packageWeight={packageWeight}
            setPackageWeight={setPackageWeight}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 4 && (
          <Step4
            handleDeleteContentPicture={handleDeleteImage(setContentPictures)}
            setContentPictures={setContentPictures}
            contentPictures={contentPictures}
            handleRejectTakeback={handleRejectTakeback}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 5 && (
          <Step5
            sortedItems={sortedItems}
            setSortedItems={setSortedItems}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 6 && (
          <Step6
            handleDeleteNonConfirmingPicture={handleDeleteImage(
              setNonconformingPictures
            )}
            setNonconformingPictures={setNonconformingPictures}
            nonConformingPictures={nonConformingPictures}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 7 && (
          <Step7
            isRejected={isRejected}
            scannedResult={scannedResult}
            packagePictures={packagePictures}
            contentPictures={contentPictures}
            nonConformingPictures={nonConformingPictures}
            isWeightValid={isWeightValid}
            handleFinalSubmit={handleFinalSubmit}
            handlePreviousStep={handlePreviousStep}
            sortedItems={sortedItems}
            packageWeight={packageWeight}
            controlContainerWeight={controlContainerWeight}
            isGreenLight={isGreenLight}
            setIsGreenLight={setIsGreenLight}
          />
        )}
      </div>
      {(isLoadingSubmit || saveError) && (
        <div className="fixed w-full h-full top-0 bg-black/80 flex justify-center items-center">
          <div className="w-[200px] bg-white p-4 text-center">
            {step === 1 ? (
              <p>Opening takeback...</p>
            ) : (
              <p>Saving takeback...</p>
            )}
            {saveError && (
              <>
                <p className="text-red-600">
                  There was an issue saving the takeback: {saveError.message}
                </p>
                <button
                  onClick={() => {
                    setSaveError("");
                  }}
                  className={`border border-gray-200 bg-yellow hover:bg-blue-700 font-bold py-2 px-4 rounded`}
                >
                  OK
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiveTakeback;
