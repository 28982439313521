import { useEffect, useState, useContext } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Input } from "components/input";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import AuthContext from "contexts/AuthContext";
import { EmailValidation } from "../../components/email-validation";
import { isValidEmail } from "lib/utils";
import { useTranslation } from "react-i18next";
import InputValidation from "components/input-validation";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import ForgotPasswordLink from "components/forgot-password-link";
import PasswordInput from "components/password-input";

export default function SignInPage() {
  const { t } = useTranslation();
  const [emailAlarm, setEmailAlarm] = useState(false);

  //reset right side visual
  const { setSidebarVisual, emailInput, setIsLoading } = useOutletContext();

  useEffect(() => {
    setSidebarVisual("man");
  }, [setSidebarVisual]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  const [username, setUsername] = useState(emailInput);
  const [password, setPassword] = useState("");

  //handle Input change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    setEmailAlarm(!isValidEmail(value));
  };

  //auth
  const { loginHandler, loginError } = useContext(AuthContext);

  //handle submit
  const submitHandler = () => {
    setIsLoading(t("ui.signing_in"));
    loginHandler(
      { username, password },
      {
        onSuccess: () => {
          const navigateOptions = {
            pathname:
              queryParams[TAKEBACK_METHOD_NAME] === "mail"
                ? t("urls.trade_in_terms")
                : t("urls.trade_in_summary"),
          };
          if (queryParams) {
            navigateOptions.search = createSearchParams(queryParams).toString();
          }
          navigate(navigateOptions);
          navigate(0);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        },
      },
      {
        onError: () => {
          setIsLoading(false);
        },
      }
    );
  };

  const returnTo = `${location.pathname}${location.search}`;

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      onSubmit={submitHandler}
      submitParams={queryParams}
      secondaryLabel={t("ui.button_back")}
      secondaryHref={`${t("urls.signup_store_sign_in")}`}
      secondaryParams={queryParams}
    >
      <ProgressBar step={5} />
      <h2 className="font-semibold text-6xl max-w-lg">
        {t("ui.welcome_back")}
      </h2>
      <div className="w-full flex flex-col gap-x-large">
        <div>
          <Input
            label={t("ui.input_email")}
            type="email"
            id="username"
            value={username}
            onChange={handleEmailChange}
          />
          <EmailValidation emailAlarm={emailAlarm} />
        </div>
        <div>
          <PasswordInput
            label={t("ui.input_password")}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="text-blue mt-regular text-sm cursor-pointer">
            <ForgotPasswordLink returnToPath={returnTo} />
          </div>
        </div>
        <InputValidation errorMessage={loginError?.message} />
      </div>
    </FormContainer>
  );
}
