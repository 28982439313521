import React, {
  // useRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import AuthContext from "contexts/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { useCurrentLocation, getQueryParams } from "lib/utils";
import { Trans, useTranslation } from "react-i18next";

import "material-symbols/outlined.css";
import "./style.css";

import { Header } from "components/header";
import { Footer } from "components/footer/footer";
import { Button } from "components/button";

//calc
import { calculateEstimate } from "lib/calculateEstimate";
import InputValidation from "components/input-validation";
//import { formatEstimationNumber } from "lib/utils";
import weightRestrictions from "data/weight-restrictions.json";

//query params
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";

//assets
import LegoMan from "assets/legoman.png";
import BgMailIn from "assets/landingMailIn.png";
import BgMailInEuro from "assets/landingMailInEuro.png";
import BgInStore from "assets/landingInStore.png";
import BgCredit from "assets/landingCredit.png";
import BgEstimate from "assets/landingEstimate.png";
import BgEstimateEuro from "assets/landingEstimateEuro.png";
// import BgEstimateEuro from "assets/bgEuro.jpg";
import BgBricks from "assets/landingBricks.png";
import BgAccepted from "assets/landingAccepted.png";
import BgNotAccepted from "assets/landingNotAccepted.png";
import BgLandingHero from "assets/landingHero.png";
import BgLandingHeroEuro from "assets/landingHeroEuro.png";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";
import useLocalizedCurrencyValue from "hooks/useLocalizedCurrencyValue";
import RewardOverviewLanding from "components/reward-overview-landing";

const Landing = () => {
  const [takeBackTypeToggle, setTakeBackTypeToggle] = useState("mail");
  const [showInStore, setShowInStore] = useState(false);
  const [weightInput, setWeightInput] = useState("");
  const [weightAlarm, setWeightAlarm] = useState(null);

  // translation
  const { t } = useTranslation();
  // const { i18n } = useTranslation();

  // auth
  const { user } = useContext(AuthContext);

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  const unit = `${t("scale.weight")}`;
  const storeLocation =
    currentLocation && currentLocation[STATE_NAME] === "GA"
      ? "Sugarloaf Mills"
      : "Colorado Mills";

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={`${t("urls.trade_in_weight")}`}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
          ...(parsedWeightInput && !weightAlarm && { w: parsedWeightInput }),
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };

  const moveTo = (elementId, offset = 0) => {
    const element = document.getElementById(elementId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  const handleScrollTo = (e, elementId, offset) => {
    e.preventDefault();
    moveTo(elementId, offset);
  };

  const updateShowInStore = useCallback(() => {
    setShowInStore(
      currentLocation.takebackMethod.some(
        (method) => method === "in_store_co" || method === "in_store_ga"
      )
    );
  }, [currentLocation]);

  useEffect(() => {
    updateShowInStore();
  }, [updateShowInStore]);

  const parsedWeightInput = parseFloat(weightInput.replace(",", "."));
  const estimate = calculateEstimate(
    parsedWeightInput,
    currentLocation?.country
  );
  const pricePerUnit = calculateEstimate(1, currentLocation?.country);
  // const singleValue =
  //   currentLocation?.takebackDisbursement?.includes("giftcard") &&
  //   currentLocation?.takebackDisbursement?.length === 1
  //     ? estimate.gift
  //     : estimate.payment;
  // const displaySingleValue =
  //   currentLocation?.locale !== "en-US"
  //     ? singleValue.toString().replace(".", ",")
  //     : singleValue;
  const estimateGift = useLocalizedCurrencyValue(estimate.gift);
  const estimateCash = useLocalizedCurrencyValue(estimate.payment);
  const pricePerUnitGift = useLocalizedCurrencyValue(pricePerUnit.gift);
  const pricePerUnitCash = useLocalizedCurrencyValue(pricePerUnit.payment);

  const maxWeight =
    currentLocation?.takebackMethod.includes("mail") &&
    currentLocation?.takebackMethod.length === 1
      ? weightRestrictions.find((weight) => weight.unit === unit).maxMail
      : weightRestrictions.find((weight) => weight.unit === unit).maxInstore;

  const minWeight = weightRestrictions.find(
    (weight) => weight.unit === unit
  ).minMail;

  const handleCalcInput = (e) => {
    let inputValue = e.target.value;

    if (currentLocation?.locale !== "en-US") {
      inputValue = inputValue.replace(/[^\d,]/g, "");
      const parts = inputValue.split(",");

      if (parts[1]) {
        inputValue = `${parts[0]},${parts[1].slice(0, 1)}`;
      }

      const numericValue = parseFloat(inputValue.replace(",", "."));
      e.target.value =
        !isNaN(numericValue) && numericValue >= 0
          ? inputValue
          : inputValue === ","
            ? ","
            : "";
    } else {
      inputValue = inputValue.replace(/[^\d.]/g, "");
      const parts = inputValue.split(".");

      if (parts[1]) {
        inputValue = `${parts[0]}.${parts[1].slice(0, 1)}`;
      }

      const numericValue = parseFloat(inputValue);
      e.target.value =
        !isNaN(numericValue) && numericValue >= 0
          ? inputValue
          : inputValue === "."
            ? "."
            : "";
    }

    handleCalcInputChange(e);
  };

  const handleCalcInputChange = (event) => {
    const newValue = event.target.value;
    setWeightInput(newValue);
    setWeightAlarm(null);
    const numericValue = parseFloat(newValue.replace(",", "."));

    if (!isNaN(numericValue)) {
      if (maxWeight && numericValue > maxWeight) {
        setWeightAlarm(
          t("ui.weight_alarm_max", {
            maxWeight: maxWeight,
            unit: unit,
          })
        );
      } else if (minWeight && numericValue < minWeight) {
        setWeightAlarm(
          t("ui.weight_alarm_min", {
            minWeight: minWeight,
            unit: unit,
          })
        );
      } else {
        setWeightAlarm(null);
      }
    } else {
      setWeightAlarm(null);
    }
  };

  // const AccordionTrigger = React.forwardRef(
  //   ({ children, className, ...props }, forwardedRef) => (
  //     <Accordion.Header className="AccordionHeader text-primary text-2xl">
  //       <Accordion.Trigger
  //         className={classNames("AccordionTrigger text-left", className)}
  //         {...props}
  //         ref={forwardedRef}
  //       >
  //         <div>{children}</div>
  //         <span
  //           className="AccordionChevron material-symbols-outlined align-bottom ml-large text-secondary"
  //           aria-hidden
  //         >
  //           keyboard_arrow_down
  //         </span>
  //       </Accordion.Trigger>
  //     </Accordion.Header>
  //   )
  // );

  // const AccordionContent = React.forwardRef(
  //   ({ children, className, ...props }, forwardedRef) => (
  //     <Accordion.Content
  //       className={classNames("AccordionContent", className)}
  //       {...props}
  //       ref={forwardedRef}
  //     >
  //       <div className="AccordionContentText text-secondary mt-large mb-regular">
  //         {children}
  //       </div>
  //     </Accordion.Content>
  //   )
  // );

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  return (
    <div>
      <Header classNames="bg-yellow border-none">
        <div className="flex gap-large">
          <CtaButton className="hidden md:block" />
          <Button
            href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
            variant="secondary"
            queryParams={queryParams}
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <div
        id="hero"
        className="bg-gradient-to-b from-[#FFF087] via-[#FFE950] to-[#FFE21B] px-x-large md:px-xxxx-large relative"
      >
        <div className="bg-white/50 p-small text-center text-xs absolute bottom-0 left-0 right-0 z-20">
          <Trans
            i18nKey={"landing.pentatonic"}
            components={{
              1: (
                <Link
                  to={t("urls.terms_and_conditions")}
                  className="cursor-pointer text-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </div>
        <div className="container mx-auto flex flex-col md:flex-row md:items-end gap-xx-large max-w-6xl relative z-10 pb-xxx-large md:pb-0">
          <div className="mx-auto md:mx-0 mt-xx-large md:mt-xxxx-large max-w-2xl mb-xx-large md:mb-[200px] lg:mb-xxxx-large">
            <h1 className="text-primary font-bold text-[3rem] leading-[3.875rem] md:text-7xl lg:text-8xl mb-large">
              <Trans i18nKey={"landing.hero.heading"} />
            </h1>
            <p className="text-secondary text-2xl mb-xx-large pr-0 md:pr-56 lg:pr-0">
              {showPayment
                ? t("landing.hero.sub_heading_withPayment")
                : t("landing.hero.sub_heading")}
            </p>
            <div className="flex flex-col md:flex-row gap-y-large md:gap-x-x-large">
              <CtaButton />
              <Button
                href="#"
                onClick={(e) => handleScrollTo(e, "howItWorks", 80)}
                variant="secondary"
                icon="arrow_downward"
              >
                {t("landing.hero.secondary_btn")}
              </Button>
            </div>
          </div>
        </div>
        <img
          src={
            currentLocation.country === "US" ? BgLandingHero : BgLandingHeroEuro
          }
          alt={t("landing.hero.image_alt")}
          className="hidden md:block md:absolute md:right-0 md:bottom-0"
        />
      </div>
      <div id="howItWorks">
        <div className="flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold text-center mb-large">
            {t("landing.how_it_works.heading")}
          </h2>
          <p className="text-secondary text-2xl text-center">
            {t("landing.how_it_works.sub_heading")}
          </p>
          <div className="mt-xx-large md:mt-xxx-large">
            {showInStore ? (
              <div className="mb-xx-large md:mb-xxxx-large">
                <h3 className="text-primary text-2xl font-bold md:text-4xl text-center">
                  Select a trade-in method
                </h3>
                <div className="flex flex-col md:flex-row lg:max-w-xl mx-auto mt-regular md:mt-large bg-gray-300 rounded-xl p-x-small text-primary">
                  <button
                    onClick={() => setTakeBackTypeToggle("mail")}
                    className={`${
                      takeBackTypeToggle === "mail"
                        ? "bg-black text-white "
                        : ""
                    }rounded-lg py-regular px-x-large text-2xl flex flex-1 items-center justify-center`}
                  >
                    <span className="material-symbols-outlined align-bottom mr-large scale-125">
                      package_2
                    </span>
                    <span className="block truncate leading-10">
                      {t("landing.method_mail")}
                    </span>
                  </button>
                  <button
                    onClick={() => setTakeBackTypeToggle("store")}
                    className={`${
                      takeBackTypeToggle === "store"
                        ? "bg-black text-white "
                        : ""
                    }rounded-lg py-regular px-x-large text-2xl flex flex-1 items-center justify-center`}
                  >
                    <span className="material-symbols-outlined align-bottom mr-large scale-125">
                      storefront
                    </span>
                    <span className="block truncate leading-10">
                      {t("landing.method_store")}
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-col lg:flex-row justify-stretch mx-auto gap-xxx-large lg:max-w-6xl">
              <div className="md:basis-1/3 flex flex-col md:flex-row-reverse lg:flex-col lg:max-w-[357px]">
                <div className="md:basis-1/2">
                  <img
                    className="w-full h-full object-cover"
                    src={
                      currentLocation.country === "US"
                        ? BgEstimate
                        : BgEstimateEuro
                    }
                    alt=""
                  />
                </div>

                <div className="p-x-large bg-[#DF7D2F] flex flex-col md:basis-1/2 grow justify-between">
                  <div className="mb-large">
                    <div className="flex gap-large mb-large">
                      <span className="bg-white text-center text-primary leading-[24px] font-bold text-2xl rounded-full w-6 h-6 shrink-0">
                        1
                      </span>
                      <h4 className="text-primary text-3xl font-bold">
                        {t("landing.how_it_works.step_1.heading")}
                      </h4>
                    </div>
                    <p className="text-secondary text-base">
                      {t("landing.how_it_works.step_1.body")}
                    </p>
                  </div>
                  <div>
                    <Button
                      href="#"
                      onClick={(e) => handleScrollTo(e, "estimate", 80)}
                      variant="secondary"
                      icon="arrow_downward"
                    >
                      {t("landing.how_it_works.step_1.btn")}
                    </Button>
                  </div>
                </div>
              </div>

              {takeBackTypeToggle === "mail" ? (
                <div className="md:basis-1/3 flex flex-col md:flex-row-reverse lg:flex-col lg:max-w-[357px]">
                  <div className="md:basis-1/2">
                    <img
                      src={
                        currentLocation.country === "US"
                          ? BgMailIn
                          : BgMailInEuro
                      }
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-x-large bg-[#ECB7D1] flex flex-col grow md:basis-1/2 justify-between">
                    <div className="mb-large">
                      <div className="flex gap-large mb-large">
                        <span className="bg-white text-center text-primary leading-[24px] font-bold text-2xl rounded-full w-6 h-6 shrink-0">
                          2
                        </span>
                        <h4 className="text-primary text-3xl font-bold">
                          {t("landing.how_it_works.step_2.heading")}
                        </h4>
                      </div>
                      <p className="text-secondary text-base">
                        {t("landing.how_it_works.step_2.body")}
                      </p>
                    </div>
                    <div>
                      <Button
                        href="#"
                        icon="arrow_downward"
                        onClick={(e) => handleScrollTo(e, "whatWeAccept", 80)}
                        variant="secondary"
                      >
                        {t("landing.how_it_works.step_2.btn")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="md:basis-1/3 flex flex-col md:flex-row-reverse lg:flex-col lg:max-w-[357px]">
                  <div className="md:basis-1/2">
                    <img
                      src={BgInStore}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-x-large bg-[#ECB7D1] flex flex-col md:basis-1/2 grow justify-between">
                    <div className="mb-large">
                      <div className="flex gap-large mb-large">
                        <span className="bg-white text-center text-primary leading-[24px] font-bold text-2xl rounded-full w-6 h-6 shrink-0">
                          2
                        </span>
                        <h4 className="text-primary text-3xl font-bold">
                          {t("landing.how_it_works.step_2_in_store.heading", {
                            store: storeLocation,
                          })}
                        </h4>
                      </div>
                      <p className="text-secondary text-base">
                        {t("landing.how_it_works.step_2_in_store.body")}
                      </p>
                    </div>
                    <div>
                      <Button
                        href="#"
                        variant="secondary"
                        onClick={(e) => handleScrollTo(e, "whatWeAccept", 80)}
                        icon="arrow_downward"
                      >
                        {t("landing.how_it_works.step_2_in_store.btn")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="md:basis-1/3 flex flex-col md:flex-row-reverse lg:flex-col lg:max-w-[357px]">
                <div className="md:basis-1/2">
                  <img
                    src={BgCredit}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-x-large bg-[#B4CA41] flex flex-col md:basis-1/2 grow justify-between">
                  <div className="mb-large">
                    <div className="flex gap-large mb-large">
                      <span className="bg-white text-center text-primary leading-[24px] font-bold text-2xl rounded-full w-6 h-6 shrink-0">
                        3
                      </span>
                      <h4 className="text-primary text-3xl font-bold">
                        {t("landing.how_it_works.step_3.heading")}
                      </h4>
                    </div>
                    <p className="text-secondary text-base">
                      {showPayment
                        ? t("landing.how_it_works.step_3.body_withPayment")
                        : t("landing.how_it_works.step_3.body")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxxx-large px-xxx-large flex flex-col sm:flex-row justify-center">
            <CtaButton />
          </div>
        </div>
      </div>
      <div id="whatWeAccept">
        <div className="bg-green-400 flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold text-center mb-xxx-large">
            {t("landing.what_we_accept.heading")}
          </h2>
          <div className="flex flex-col lg:flex-row justify-stretch mx-auto gap-xxx-large lg:max-w-6xl">
            <div className="bg-white p-x-large shadow-sm flex-1">
              <div className="flex gap-large mb-large">
                <img
                  src={BgAccepted}
                  alt={t("landing.what_we_accept.accepted_icon_alt")}
                  className="w-10 h-10 shrink-0"
                />
                <h3 className="text-primary text-4xl">
                  {t("landing.what_we_accept.accepted_heading")}
                </h3>
              </div>
              <ul className="list-disc list-inside pl-regular">
                <li className="mb-large">
                  <Trans i18nKey="landing.what_we_accept.accepted_bullets.item1" />
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.accepted_bullets.item2")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.accepted_bullets.item3")}
                </li>
              </ul>
            </div>
            <div className="bg-white p-x-large shadow-sm flex-1">
              <div className="flex gap-large mb-large">
                <img
                  src={BgNotAccepted}
                  alt={t("landing.what_we_accept.not_accepted_icon_alt")}
                  className="w-10 h-10 shrink-0"
                />
                <h3 className="text-primary text-4xl">
                  {t("landing.what_we_accept.not_accepted_heading")}
                </h3>
              </div>
              <ul className="list-disc list-inside pl-regular">
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item1")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item2")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item3")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item4")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item5")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="estimate">
        <div className="flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold text-center mb-xxx-large md:mb-xxxx-large">
            {t("landing.estimate_value.heading")}
          </h2>
          <div className="flex flex-col lg:flex-row w-full md:w-auto md:mx-auto lg:max-w-6xl">
            <div className="bg-yellow-300 rounded-xl flex relative max-w-2xl md:pr-[282px]">
              <div className="p-x-large flex flex-col gap-regular w-full">
                <h5 className="text-primary font-bold text-sm uppercase">
                  {t("landing.estimate_value.calculator.heading")}
                </h5>
                <div
                  className={`${
                    weightAlarm ? "text-black/25 " : ""
                  }text-[40px] font-semibold leading-none my-regular`}
                >
                  {!weightAlarm && parsedWeightInput > 0 ? (
                    <>
                      {/* {currentLocation?.locale === "de-DE"
                        ? `${displaySingleValue} ${t("currency.symbol")}`
                        : `${t("currency.symbol")}${displaySingleValue}`} */}
                      {showPayment
                        ? `${estimateCash} - ${estimateGift}`
                        : estimateGift}
                    </>
                  ) : (
                    <>
                      {currentLocation?.locale === "de-DE"
                        ? `0,00 ${t("currency.symbol")}`
                        : `${t("currency.symbol")}0.00`}
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-y-regular">
                  <label>
                    {t("landing.estimate_value.calculator.input_label")}
                  </label>
                  <div className="flex items-center rounded-lg border border-gray-400 overflow-hidden">
                    <input
                      type="text"
                      inputMode="decimal"
                      className="flex-1 min-w-0 outline-none text-light text-primary h-12 px-3 py-2 placeholder-gray-300"
                      placeholder="0"
                      value={weightInput}
                      onChange={handleCalcInputChange}
                      onInput={handleCalcInput}
                      name="weight"
                      lang={currentLocation.locale}
                    />
                    <div className="text-secondary px-2 bg-gray-400 shrink-0 h-12 flex items-center">
                      {t("scale.weight_label")}
                    </div>
                  </div>
                  {weightAlarm && (
                    <InputValidation errorMessage={weightAlarm} />
                  )}
                  {!showPayment && (
                    <div className="flex text-sm content-center text-tertiary mt-small">
                      <span
                        className="material-symbols-outlined align-bottom mr-small scale-75"
                        aria-hidden
                      >
                        info
                      </span>
                      <span>
                        {t("landing.estimate_value.calculator.input_note", {
                          unit: t("scale.weight_label_singular"),
                          value: showPayment
                            ? `${pricePerUnitCash} - ${pricePerUnitGift}`
                            : pricePerUnitGift,
                        })}
                        <button
                          onClick={(e) =>
                            handleScrollTo(e, "whatWeAccept", 100)
                          }
                          title="Navigate to What we accept"
                          className="text-blue hover:underline"
                        >
                          {t(
                            "landing.estimate_value.calculator.input_note_link"
                          )}
                          .
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <img
                src={LegoMan}
                alt="Smiling LEGO Minifigure"
                className="md:absolute md:right-large md:bottom-0 w-[282px] md:w-[246px] hidden sm:block"
              />
            </div>
          </div>
          {showPayment && (
            <RewardOverviewLanding handleScrollTo={handleScrollTo} />
          )}
          <div className="mt-xxx-large md:mt-xxxx-large px-xxx-large flex flex-col sm:flex-row justify-center">
            <CtaButton />
          </div>
        </div>
      </div>
      <div id="whatHappens" className="flex flex-col sm:flex-row mx-auto ">
        <div className="basis-1/2">
          <img
            src={BgBricks}
            alt="A mixture of colorful LEGO bricks"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="bg-green p-x-large md:p-xxx-large lg:p-xxxx-large basis-1/2">
          <div className="max-w-2xl">
            <h1 className="text-white text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold mb-x-large">
              {t("landing.what_happens.heading")}
            </h1>
            <p className="text-green-300 text-2xl mb-large">
              {t("landing.what_happens.body1")}
            </p>
            <p className="text-green-300 text-2xl">
              {t("landing.what_happens.body2")}
            </p>
          </div>
        </div>
      </div>
      {/* <div id="faqs">
        <div className="flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold text-center mb-xxx-large">
            {t("landing.faqs.heading")}
          </h2>
          <div className="mx-auto w-full lg:max-w-6xl">
            <Accordion.Root type="single" defaultValue="item-1" collapsible>
              <Accordion.Item
                className="p-large border-b border-gray-300"
                value="item-1"
              >
                <AccordionTrigger className="flex justify-between w-full">
                  {t("landing.faqs.questions.item1.question")}
                </AccordionTrigger>
                <AccordionContent>
                  {t("landing.faqs.questions.item1.answer")}
                </AccordionContent>
              </Accordion.Item>
              <Accordion.Item
                className="p-large border-b border-gray-300"
                value="item-2"
              >
                <AccordionTrigger className="flex justify-between w-full">
                  {t("landing.faqs.questions.item2.question")}
                </AccordionTrigger>
                <AccordionContent>
                  {t("landing.faqs.questions.item2.answer")}
                </AccordionContent>
              </Accordion.Item>
              <Accordion.Item
                className="p-large border-b border-gray-300"
                value="item-3"
              >
                <AccordionTrigger className="flex justify-between w-full">
                  {t("landing.faqs.questions.item3.question")}
                </AccordionTrigger>
                <AccordionContent>
                  {t("landing.faqs.questions.item3.answer")}
                </AccordionContent>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Landing;
