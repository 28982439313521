import { cn } from "lib/utils";
import React from "react";
import { Trans } from "react-i18next";

export default function PaymentConfirmation({ className, heading, message }) {
  return (
    <div className={cn("mt-large", className)}>
      <div className="bg-[#c0dfc3] flex rounded-lg w-full h-full overflow-hidden">
        <div className="h-full w-[5px] bg-[#00963e]"></div>
        <div className="flex flex-col p-4">
          <div className="font-semibold">
            {heading || (
              <Trans i18nKey={"my_trade_ins.payment_confirmation_title"} />
            )}
          </div>
          <div>
            {message || (
              <Trans i18nKey={"my_trade_ins.payment_confirmation_body"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
